<template>
  <div class="share-button">
    <div
      class="share-button__links"
      :class="{ 'share-button__links-open': isOpen, 'share-button__links-closed': !isOpen }"
    >
      <a
        class="share-button__link"
        v-for="(item, index) in icons"
        :key="index"
        :href="item.href"
        :aria-label="item['aria-label']"
      >
        <FontAwesomeIcon :icon="item.icon" size="1x" />
      </a>
    </div>
    <div class="share-button-wrapper">
      <slot />
      <ButtonComponent
        class="kpb-button share-button__share"
        @click="toggle"
        v-bind="{ ...data }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    icons() {
      return [
        {
          'aria-label': 'Del via e-post',
          icon: ['far', 'envelope'],
          href: `mailto:?subject?PrivatMegleren&body=${window.location.href}`,
          //   href: 'mailto:?subject=PrivatMegleren&body=https%3A%2F%2Fprivatmegleren.no%2Feiendom%2Fsolsiden%2F3558898'
        },
        {
          'aria-label': 'Del på Facebook',
          icon: ['fab', 'facebook-square'],
          href: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
          // https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fprivatmegleren.no%2Feiendom%2Fsolsiden%2F3558898
        },
        {
          'aria-label': 'Del på Twitter',
          icon: ['fab', 'twitter'],
          href: `https://twitter.com/home?status=${window.location.href}`,
          // https://twitter.com/home?status=https%3A%2F%2Fprivatmegleren.no%2Feiendom%2Fsolsiden%2F3558898
        },

        {
          'aria-label': 'Del på Linkedin',
          icon: ['fab', 'linkedin'],
          href: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
          // https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fprivatmegleren.no%2Feiendom%2Fsolsiden%2F3558898
        },
      ]
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss">
.share-button {
  width: max-content;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  // gap: 1rem;

  &-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;

    @include respond-below('phone') {
      flex-direction: column;
    }
  }

  &__link {
    font-size: 1.5rem;
    color: var(--primary);
  }

  &__links {
    justify-content: center;
    display: flex;
    gap: 1rem;

    transition: all 2s, height 0.5s;

    &-closed {
      height: 0px;
      opacity: 0;
      transform: translate3d(0px, 20px, 0px);
    }

    &-open {
      height: 50px;
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }
  }

  &__share {
  }
}
</style>
