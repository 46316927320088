<template>
  <div v-if="value.length" class="flatfinder">
    <LazyLoad @intersect="intersect = true" />
    <div class="flatfinder__container">
      <div class="flatfinder__header">
        <slot name="title">
          <h1 class="flatfinder__title">{{ current.name }}</h1>
        </slot>
        <FilterComponent
          class="flatfinder__filter"
          v-if="showFilter && isMobile"
          :is-mobile="isMobile"
          @filter="$ev => (filter = $ev)"
        />
      </div>
      <Loader :value="promise" theme="overlay" class="flatfinder__content">
        <component
          :is-mobile="isMobile"
          :is="flatfinder"
          :key="current.id"
          :image="intersect ? current.image.url : ''"
          :dimensions="{ width: current.image.dimensions[0], height: current.image.dimensions[1] }"
          :value="items"
          v-bind="$attrs"
          @switch="setCurrent"
          @click="onClick"
        >
          <template #top-right>
            <div v-if="filter.length" class="flatfinder__filter-count flatfinder__overlay">
              {{ $t('foundXResults', [items.length]) }}
            </div>
          </template>
          <template #bottom-left>
            <div class="flatfinder__attribution flatfinder__overlay">
              {{ $t('imagesOnlyAsIllustration') }}
            </div>
          </template>
          <template #bottom-right>
            <div class="flatfinder__list">
              <button
                v-if="parent"
                class="flatfinder__list-item"
                :style="{ backgroundImage: `url(${parent.image.url})` }"
                @click="() => setCurrent(parent)"
              >
                <span class="flatfinder__list-item-label">{{ $t('goBack') }}</span>
              </button>
              <button
                v-for="item in children"
                :key="item.id"
                class="flatfinder__list-item"
                :style="{ backgroundImage: `url(${item.image.url})` }"
                @click="() => setCurrent(item)"
              >
                <span class="flatfinder__list-item-label">{{ item.name }}</span>
              </button>
            </div>
          </template>
        </component>
      </Loader>
      <div class="flatfinder__filter-wrapper" v-if="showFilter && !isMobile">
        <FilterComponent :is-mobile="isMobile" @filter="$ev => (filter = $ev)" />
      </div>
    </div>
  </div>
</template>

<script>
import Flatfinder from './Flatfinder'
import LazyLoad from 'vue-make-lazy'
import FilterComponent from './Filter'
import { getFlatfinder } from './api'
import { path, Debounce } from './utils'
import mitt from 'mitt'

const eventBus = mitt()

export { eventBus }
export default {
  provide() {
    return {
      current: this.current,
    }
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    suggested: {
      type: Object,
    },
    properties: {
      type: Array,
      default: () => [],
    },
    preventNavigation: {
      type: Boolean,
      default: false,
    },
    breakAt: {
      type: Number,
      default: 767,
    },
    customStatusFilter: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value: {
      handler: 'setDefault',
      immediate: true,
    },
    filter: {
      handler() {
        this.fetchDebounce()
      },
    },
    intersect: {
      handler: 'fetch',
      immediate: true,
    },
    current: {
      handler() {
        this.$nextTick(() => {
          eventBus.emit('onChange', this.current)
        }),
          this.fetch()
      },
      immediate: true,
    },
  },
  data() {
    return {
      current: {},
      item: {},
      filter: [],
      promise: null,
      intersect: false,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    isMobile() {
      return this.breakAt >= this.windowWidth
    },
    flatfinder() {
      return Flatfinder
    },
    visibleItemIds() {
      if (!this.item.id) return []
      return this.item.items.map(i => i.reference.id)
    },
    items() {
      if (this.current.id !== this.item.id) return []
      return this.item.items || []
    },
    showFilter() {
      return (
        this.intersect &&
        (this.items.some(i => i.reference.onModel === 'Residential') || this.filter.length)
      )
    },
    children() {
      return this.value.filter(
        v =>
          v.parent &&
          v.parent.id === path('current.id', this) &&
          !this.visibleItemIds.includes(v.id),
      )
    },
    siblings() {
      return this.value.filter(v => {
        if (v.id === this.current.id) return false
        return path('parent.id', v) === path('current.parent.id', this)
      })
    },
    parent() {
      return this.value.find(v => v.id === path('current.parent.id', this))
    },
    entry() {
      if (!this.value.length) return
      return this.value.find(item => item.isEntry) || this.value[0]
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    setDefault(force = false) {
      if (this.current.id && !force) return
      this.current = this.suggested || this.entry
    },
    setCurrent(item) {
      this.current = item
      this.storeCurrent()
    },
    onClick(item) {
      eventBus.emit('click', item)
    },
    storeCurrent() {
      window.sessionStorage.setItem('flatfinder_current', JSON.stringify(this.current))
    },
    fetchDebounce: Debounce(function() {
      this.fetch()
    }, 500),
    fetch() {
      if (!this.current.id || !this.intersect) return

      let statusFilter = ['!draft']

      if (Object.keys(this.filter).length) statusFilter.push('!sold', '!rented')

      if (window.Kvass && this.filter.length) {
        Kvass.emit('track', {
          event: 'filter',
          data: { filter: JSON.stringify(this.filter) },
        })
      }

      this.promise = getFlatfinder({
        id: this.current.id,

        properties: this.properties,
        filter: {
          status: [...statusFilter, ...this.customStatusFilter],
          properties: this.filter,
        },
      }).then(item => (this.item = item))
    },
  },
  created() {
    try {
      let item = JSON.parse(window.sessionStorage.getItem('flatfinder_current'))
      if (item && item.id) this.current = item
    } catch (err) {}

    eventBus.on('setCurrent', this.setCurrent)
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  components: {
    FilterComponent,
    LazyLoad,
  },
}
</script>

<style lang="scss">
.flatfinder {
  position: relative;

  @include respond-below('phone') {
    .flatfinder-leaflet-v1__bottom-left {
      width: 100%;
      top: 100%;
      text-align: center;
    }

    .flatfinder-leaflet-v1__aspect {
      margin-bottom: 2.5rem;
    }
  }

  &__title {
    text-align: center;
    margin: 0;
  }

  &__header {
    margin-bottom: 1rem;
    @include respond-below('phone') {
      padding: 0 1rem;
      display: flex;
      gap: 0.2rem;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      .flatfinder-filter {
        margin: 0;
        &__trigger {
          padding: 0.5rem;
          font-size: 0.9em;
          &-add {
            justify-content: end;
          }
        }
      }
    }
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
  }

  &__filter {
    &-wrapper {
      min-height: 80px;
    }
  }

  &__filter-count {
    @include respond-below('phone') {
      border-radius: 0 0 0 var(--border-radius) !important;
    }
  }

  &__container {
    $spacing: 6rem;
    padding: 0 $spacing;
    min-height: 70vh;

    @include respond-below('phone') {
      padding: 0;
      min-height: 35vh;
    }
  }

  .elder-button--default {
    font-size: 0.7em;
    background-color: rgba(white, 0.9) !important;
    color: #272727 !important;

    &:hover {
      background-color: rgba(white, 1) !important;
    }
  }

  &__overlay {
    padding: 0.5rem 1rem;
    background-color: rgba(black, 0.3);
    backdrop-filter: blur(8px);
    border-radius: var(--border-radius);
    color: white;
    box-shadow: 0 5px 8px 0px rgba(black, 0.2);

    @include respond-below('phone') {
      padding: 0.25rem 0.5rem;
      font-size: 0.6em;
    }
  }

  &__attribution {
    font-size: 0.8em;
    white-space: nowrap;

    @include respond-below('phone') {
      background: none;
      box-shadow: none;
      backdrop-filter: none;
      color: rgba(black, 0.5);
      padding: 0.5rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include gap(0.5rem);

    @include respond-below('phone') {
      @include gap(0.25rem);
    }

    &-item {
      background-color: rgba(white, 0.7);
      background-position: center;
      background-size: cover;
      box-shadow: 0 5px 8px 0px rgba(0, 0, 0, 0.2);
      border-radius: $border-radius;
      cursor: pointer;
      overflow: hidden;
      min-width: 140px;
      min-height: 100px;
      transition: transform 150ms ease-out;

      @include respond-below('phone') {
        min-height: 40px;
        min-width: 100px;
      }

      &:hover {
        .flatfinder__list-item-label {
          opacity: 0;
        }
      }

      &-label {
        font-weight: bold;
        padding: 0.5rem 1rem;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(black, 0.3);
        backdrop-filter: blur(3px);
        color: white;
        transition: opacity 200ms ease-out;

        @include respond-below('phone') {
          font-size: 0.8em;
        }
      }
    }
  }

  .elder-loader__element {
    z-index: 400;
    background-color: transparent;
  }

  .elder-loader__element-content-inner {
    margin: 1rem;
    background-color: rgba(black, 0.3) !important;
    border: none !important;
    color: white;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    padding: 0.75rem 2rem 0.75rem 0.75rem;
    min-width: initial;
    gap: 1rem;
  }

  .elder-loader__loading-message {
    margin: 0;
  }
}
</style>
