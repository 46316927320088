<template>
  <LazyLoad @intersect="initDemographics">
    <ReadMore class="demographics-section" v-if="profile" height="850px">
      <template>
        <Demographics :value="doughnutDemographics" :interactiveUrl="interactiveUrl" />

        <Population :profile="profile" />

        <DistanceStats :value="stats" />

        <Qualities :value="ratings" />

        <section class="demographics-section__footer">
          <p>
            Informasjon i Nabolagsprofilen er hentet fra ulike kilder og det kan forekomme feil
            eller mangler i dataene. Distanser er basert på korteste gang- eller kjørbare vei (og
            luftlinje i noen tilfeller). Vurderingene, sitatene og bildene fra lokalkjente er
            innhentet på nettstedet Nabolag.no og er aggregerte data basert på svar innenfor et gitt
            nabolag. Eiendomsprofil AS, FINN.no AS, Visma Real Estate AS, innholdsleverandørene (se
            kilder) eller PrivatMegleren kan ikke holdes ansvarlig for feil eller mangler i dataene.
            Kilder: Statistisk sentralbyrå (SSB), Statens kartverk, Geodata AS, Nabolag.no m.fl.
            Kartene er levert av Finn/Norkart. Vi benytter informasjonskapsler (cookies) til å lage
            generelle statistikker om bruken av våre tjenester. Ingen sensitiv bruker-informasjon
            samles inn, og vi selger ei heller brukerdata videre til andre. Copyright ©
            Eiendomsprofil AS {{ year }}
          </p>
        </section>
      </template>
    </ReadMore>
  </LazyLoad>
</template>

<script>
import Demographics from './components/Demographics.vue'
import Qualities from './components/Qualities.vue'
import DistanceStats from './components/DistanceStats.vue'
import Population from './components/Population.vue'
import LazyLoad from 'vue-make-lazy'
import ReadMore from '../ReadMore.vue'

import { animate, inView, stagger } from 'motion'

export default {
  props: {
    data: {
      estateId: String,
      system: String,
    },
  },
  data() {
    return {
      promise: null,
      profile: null,
      year: new Date().getFullYear(),
    }
  },
  computed: {
    interactiveUrl() {
      const id = this.$path('info.orderLineId', this.profile)

      if (!id) return

      return `https://profil.nabolag.no/${id}`
    },

    ratings() {
      // 1007 = Kvalitet på skolene
      // 1008 = Kvalitet på barnehagene
      // 1006 = Trygghet der barna ferdes
      // 1004 = Naboskapet
      // 1003 = Opplevd trygghet
      // 1005 = Støynivået
      // 1001 = Vedlikehold veier
      // 1002 = Vedlikehold hager
      // 1010 = Kollektivtilbud
      // 1012 = Trafikk
      // 1011 = Gateparkering
      // 1009 = Turmulighetene
      // 2016 = Aktivitetstilbud
      // 2017 = Kulturtilbud
      // 2019 = Serveringstilbud
      // 2018 = Uteliv
      // 2014 = Shoppingutvalg
      // 2015 = Dagligvaretilbud
      // 1013 = Matvareutvalg

      return [
        this.rating('1008'),
        this.rating('1003'),
        this.rating('1010'),
        this.rating('1007'),
      ].filter(Boolean)
    },

    stats() {
      return [
        this.stat('Skoler'),
        this.stat('Transport'),
        this.stat('Sport'),
        this.stat('Varer/tjenester'),
      ].filter(Boolean)
    },

    doughnutDemographics() {
      if (!this.profile) return []

      const values = this.$path('demographics.percent_text', this.profile)

      if (!values || !values.length) return []

      return values
    },
  },
  methods: {
    rating(id) {
      const ratings = this.$path('nabolag.ratings', this.profile)

      return (ratings || []).find(r => r.id === id)
    },

    stat(name) {
      const groups = this.$path('poiGroups', this.profile)

      return (groups || []).find(g => g.name === name)
    },

    async initAnimation() {
      const lines = document.querySelectorAll('.demographics-section-title')

      inView(lines, ({ target }) => {
        animate(target, { width: '100%', transform: 'none' }, { duration: 0.8, delay: 0 })
      })
    },

    initDemographics() {
      const url =
        'https://privatmegleren.kvass.no/api/integration/custom/privatmegleren/statics/eiendomsprofil'

      this.promise = fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          estateId: this.data.estateId,
          system: this.data.system,
        }),
      })
        .then(res => {
          if (!res.ok) throw new Error('')
          return res.json()
        })
        .then(({ profile }) => (this.profile = profile))
        .then(() => setTimeout(() => this.initAnimation(), 100))
        .catch(err => {
          throw err
        })
    },
  },
  mounted() {},

  components: {
    Demographics,
    Qualities,
    DistanceStats,
    Population,
    LazyLoad,
    ReadMore,
  },
}
</script>

<style lang="scss">
.demographics-section {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-block: var(--section-padding-block);

  &-title {
    // for animation
    width: 0;

    padding-block: 0.3rem;
    margin-bottom: 3rem;
    @include respond-below('phone') {
      margin-bottom: 2rem;
    }
    border-bottom: 1px solid var(--primary);
    border-image: url('https://templates.kvass.no/custom-pm-1/assets/horizontal_line.svg') 0 0 100 0 /
      2px;
  }

  &__footer {
    font-size: 0.6rem;
  }
}
</style>
