<template>
  <div class="key-stats" :style="`--custom-pm-key-stats-columns: ${columnsComp}`">
    <div class="key-stats__properties" v-for="(item, index) in content" :key="index">
      <div class="key-stats__label">{{ item.label }}</div>
      <div class="key-stats__value" v-html="item.value"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      content: Array,
    },
    columns: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    content() {
      return this.data.content
    },

    columnsComp() {
      let value = this.data.content.length > this.columns ? this.columns : this.data.content.length

      return `repeat(${value}, 1fr)`
    },
  },
}
</script>

<style lang="scss">
.key-stats {
  border: 2px solid var(--primary);

  border-image: linear-gradient(
      90deg,
      #834628 5%,
      #824528 30%,
      #ffdbb9 48%,
      #b55622 65%,
      #cb9a70 90%,
      #9a5e3d 100%
    )
    1;

  display: grid;
  grid-template-columns: var(--custom-pm-key-stats-columns, 1fr 1fr 1fr 1fr);
  gap: 4rem 0.5rem;

  padding: 1.75rem;

  @include respond-below('tablet') {
    grid-template-columns: 1fr 1fr;
  }

  @include respond-below('phone') {
    grid-template-columns: 1fr;
  }

  &__properties {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    align-items: center;
  }

  &__label {
    text-transform: uppercase;
    font-weight: bold;
  }
  &__value {
    text-align: center;
    color: var(--primary);
    @include header-font(1.75rem);
  }
}
</style>
