const imageDimensions = url =>
  new Promise((resolve, reject) => {
    const img = new Image()

    // the following handler will fire after a successful loading of the image
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img
      resolve({ width, height })
    }

    // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
    img.onerror = () => {
      reject('There was some problem with the image.')
    }

    img.src = url
  })

const setImageDimensions = images => {
  return Promise.all(
    images.map(async img => {
      if (!img.url) return
      const { width, height } = await imageDimensions(img.url)

      return {
        ...img,
        dimensions: [width, height],
      }
    }),
  )
}

export { imageDimensions, setImageDimensions }
