<template>
  <div class="total-cost">
    <h3 class="total-cost__title">
      {{ totalPrice ? 'Kjøpesum og omkostninger' : 'Pris uten omkostninger' }}
    </h3>
    <div class="total-cost__price-from">
      <div class="total-cost__price-from-label">Pris fra</div>
      <div class="total-cost__price-from-value">{{ priceFrom }}</div>
    </div>
    <div class="total-cost__example" v-if="exampleResidential">
      <div class="total-cost__example-label">Eksempel</div>
      <div class="total-cost__example-unit">
        Eksempel basert på enhetsnummer {{ exampleResidentialNumber }}
      </div>
      <div class="total-cost__example-value">
        kr {{ numberWithSpaces(exampleResidentialValue) }}
      </div>
    </div>
    <ul class="total-cost__details" v-if="totalPrice">
      <li v-for="(item, index) in content" :key="index">
        <div>{{ item.label }}</div>
        <div>kr {{ numberWithSpaces(Math.round(item.value)) }}</div>
      </li>
      <li v-if="omkostningerTotal">
        <div>Omkostninger totalt</div>
        <div>kr {{ numberWithSpaces(omkostningerTotal) }}</div>
      </li>
    </ul>
    <div class="total-cost__total-price" v-if="totalPrice">
      <div class="total-cost__total-price-label">Totalpris inkl. omkostninger</div>
      <div class="total-cost__total-price-value">{{ totalPrice }}</div>
    </div>
    <div class="total-cost__joint-dept" v-if="jointDebt">
      <div class="total-cost__joint-dept-label">Andel fellesgjeld</div>
      <div class="total-cost__joint-dept-value">kr {{ numberWithSpaces(jointDebt) }}</div>
    </div>
    <div class="total-cost__total-joint-dept" v-if="jointDebt">
      <div class="total-cost__total-joint-dept-value">Totalpris ekskl. omkostninger</div>
      <div class="total-cost__total-joint-dept-value">
        {{ totalJointDebt }}
      </div>
    </div>
    <p class="total-cost__explanation" v-if="totalPrice">
      *NB: Regnestykket forutsetter at det kun tinglyses ett pantedokument og at eiendommen selges
      til prisantydning
    </p>

    <h3 class="total-cost__title">Sjekk månedlig kostnad på eiendommen</h3>

    <kvass-loan-calculator
      logo="https://assets.kvass.no/675ae751343f1d3fb4fa9e67"
      equity-percent-default="0.3"
      nominel-interest-rate-default="4.5"
      nominel-interest-rate-min="0"
      nominel-interest-rate-max="10"
      repayment-period-default="25"
      repayment-period-max="30"
      repayment-period-min="5"
      fee-monthly="65"
      fee-establishing="3200"
      :purchase-price="data.price"
      purchase-price-fixed="false"
      cta-link="https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/jeg-onsker-a-kjope-en-bolig.html?cid=partner-f4gv1ruzzn#/"
      cta-label="Få tilbud på boliglån"
    ></kvass-loan-calculator>
    <div class="total-cost__buttons">
      <ButtonComponent
        theme="secondary"
        label="Søk finansiering"
        :icon="['fas', 'external-link']"
        tag="a"
        target="_blank"
        href="https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/privatmegleren-og-nordea-finansiering.html?cid=partner-h8uh2yl6vbiz1gsb5973"
      />
      <ButtonComponent
        v-if="hasResidentials"
        theme="secondary"
        @click="ScrollToAnchor('section-residentials')"
        label="Se prisliste"
      />
    </div>
  </div>
</template>

<script>
import { ScrollToAnchor } from '@/utils'
export default {
  props: {
    data: {
      residentials: Array,
      totalCost: Array,
      price: Number,
      priceTo: Number,
      jointDebt: Number,
    },
  },

  computed: {
    hasResidentials() {
      return this.data.residentials.length
    },

    jointDebt() {
      if (!this.exampleResidential) {
        if (!this.data.price) return
        return this.data.jointDebt || 0
      }
      return this.$path('sale.jointDebt', this.exampleResidential) || 0
    },

    totalJointDebt() {
      const startPrice = this.exampleResidential ? this.exampleResidentialValue : this.data.price
      const upperPrice = this.exampleResidential ? 0 : this.data.priceTo

      const jointDebt = this.jointDebt || 0

      if (!startPrice) return

      const lower = startPrice + jointDebt

      if (upperPrice) {
        const upper = upperPrice + jointDebt
        return `kr ${this.numberWithSpaces(lower)} til kr ${this.numberWithSpaces(upper)}`
      }

      return `kr ${this.numberWithSpaces(lower)}`
    },

    omkostningerTotal() {
      return this.content.map(i => Math.round(i.value)).reduce((p, a) => p + a, 0)
    },

    totalPrice() {
      const omkostninger = this.omkostningerTotal
      const startPrice = this.exampleResidential ? this.exampleResidentialValue : this.data.price
      const upperPrice = this.exampleResidential ? 0 : this.data.priceTo

      const jointDebt = this.jointDebt || 0

      if (!startPrice || (!omkostninger && !jointDebt)) return

      const lower = omkostninger + startPrice + jointDebt

      if (upperPrice) {
        const upper = omkostninger + upperPrice + jointDebt
        return `kr ${this.numberWithSpaces(lower)} til kr ${this.numberWithSpaces(upper)}`
      }

      return `kr ${this.numberWithSpaces(lower)}`
    },

    exampleResidential() {
      if (!this.data.residentials.length) return null

      return (
        this.data.residentials.find(
          r => r.sale.type === 'sale' && r.customFields['total-cost'] && r.status === 'sale',
        ) || null
      )
    },

    content() {
      if (!this.exampleResidential) return this.data.totalCost || []

      return this.exampleResidential.customFields['total-cost'] || []
    },

    isUpcoming() {
      return !this.exampleResidential && !this.data.price
    },

    priceFrom() {
      if (!this.exampleResidential) {
        if (!this.data.price) return 'Pris etter forespørsel'

        if (this.data.priceTo && this.data.price !== this.data.priceTo)
          return `kr ${this.numberWithSpaces(this.data.price)} til kr ${this.numberWithSpaces(
            this.data.priceTo,
          )}`

        return `kr ${this.numberWithSpaces(this.data.price)}`
      }

      const value = this.data.residentials
        .filter(r => !['upcoming', 'sold'].includes(r?.status))
        .map(r => r.sale.value)
        .filter(Boolean)
        .sort((a, b) => a - b)

      if (!value.length) return ''

      return value.length < 2
        ? `kr ${this.numberWithSpaces(value[0])}`
        : `kr ${this.numberWithSpaces(value[0])} til kr ${this.numberWithSpaces(
            value[value.length - 1],
          )}`
    },

    exampleResidentialNumber() {
      return this.$path('name', this.exampleResidential)
    },

    exampleResidentialValue() {
      return this.$path('sale.value', this.exampleResidential)
    },
  },
  methods: {
    ScrollToAnchor,
    numberWithSpaces(x) {
      if (!x) return
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
  },
}
</script>

<style lang="scss">
.total-cost {
  --k-loan-background-color: #202020;
  --k-loan-primary: #e7cfa7;
  --k-loan-cta-alignment: center;
  --k-loan-slider-track-background: rgb(255 255 255 / 0.2);
  --k-loan-border-radius: 0px;

  @include respond-above('tablet') {
    padding-left: 2rem;
  }

  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__title {
    margin-bottom: 1rem;
  }

  &__price-from {
    margin-bottom: 0.5rem;
    &-label {
      font-weight: bold;
      text-transform: uppercase;
    }
    &-value {
      @include header-font(2rem);
      color: var(--primary);
    }
  }

  &__example {
    &-label {
      font-weight: bold;
      text-transform: uppercase;
    }
    &-unit {
    }
    &-value {
    }
  }

  &__details {
    list-style-type: none;
    padding-inline-start: unset;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__total-price {
    &-label {
      font-weight: bold;
    }
    &-value {
      font-weight: bold;
    }
  }

  &__joint-dept {
  }
  &__total-joint-dept {
  }
  &__explanation {
    // margin: 0;
  }
  &__buttons {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 1.5rem 1.5rem;

    @include respond-below('phone') {
      grid-template-columns: 1fr;
    }
  }
}
</style>
