import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import API from '@/api'

import Loading from '@/views/Loading.vue'
import Project from '@/views/Project.vue'

import { eventBus as ScrollAnchorBus } from '@kvass/scroll-anchor/src/Component.vue'

Vue.use(VueMeta)
Vue.use(VueRouter)

function getRoutes() {
  switch (API.projectType) {
    case 'singular':
      return [
        {
          path: '/',
          name: 'Project',
          component: ProjectResidential,
        },
        {
          path: '*',
          redirect: {
            name: 'Project',
          },
        },
      ]
    default:
      return [
        {
          path: '/',
          name: 'Project',
          component: Project,
        },

        {
          path: '/residential/:id',
          name: 'Residential',
          props: true,
          component: () =>
            LazyLoadView(import(/* webpackChunkName: "residential" */ '@/views/Residential.vue')),
        },

        {
          path: '*',
          redirect: {
            name: 'Project',
          },
        },
      ]
  }
}

const routes = getRoutes()

function LazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: Loading,
    delay: 150,
    // error: require('@views/_timeout.vue').default,
    // timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}

function getBaseUrl() {
  if (
    ['www.privatmegleren.no', 'privatmegleren.no', 'test.privatmegleren.no'].includes(
      location.hostname,
    )
  ) {
    return (
      location.pathname
        .split('/')
        .slice(0, 4)
        .join('/') + '/'
    )
  }
  if (location.host === 'localhost:9000') {
    return (
      location.pathname
        .split('/')
        .slice(0, 3)
        .join('/') + '/'
    )
  }

  return '/'
}

const router = new VueRouter({
  mode: 'history',
  base: getBaseUrl(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return ScrollAnchorBus.emit('scroll-anchor:update', to.hash)
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if (from.name === 'Residential' && to.name === 'Project')
    window.sessionStorage.removeItem('flatfinder_current')

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next(Object.assign({}, to, { query: from.query }))
  } else {
    next()
  }
})

export default router
