var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"route",class:['route--' + _vm.view.toLowerCase(), _vm.isOnTop ? 'page--is-top' : 'page--is-scrolled'],attrs:{"id":"app"}},[(_vm.$route.meta.navigation !== false)?_c('div',{staticClass:"nav",class:[
      'nav--' + _vm.view.toLowerCase(),
      { 'nav--expanded': _vm.isExpanded },
      {
        ['nav--' + _vm.view.toLowerCase() + '-expanded']: _vm.isExpanded,
        [`nav--${_vm.view.toLowerCase()}-${_vm.slug}`]: _vm.view.toLowerCase() == 'post',
      },
    ]},[_c('div',{staticClass:"container"},[_c('NavigationComponent',{attrs:{"logo":_vm.logo,"items":_vm.items,"title":_vm.projectName,"padding":"15px 0","action":_vm.mainAction || _vm.redirect(['Project', 'Residential'], { scrollTo: 0, id: 'home' }),"isOpen":_vm.isExpanded},on:{"update:isOpen":function($event){_vm.isExpanded=$event},"update:is-open":function($event){_vm.isExpanded=$event}}},[(_vm.isExpanded)?[_c('HorizontalLine'),_c('Navbar',{attrs:{"hideButton":true}})]:_vm._e()],2)],1)]):_vm._e(),(_vm.sold)?_c('img',{staticClass:"elder__navigation-sold",attrs:{"src":"https://templates.kvass.no/custom-pm-1/assets/solgt.png"}}):_vm._e(),_c('Modal',{staticClass:"navigation-modal",attrs:{"show":_vm.show,"clickaway":false},on:{"close":_vm.onClose}},[_c('Navbar',{attrs:{"logo":_vm.logo,"title":_vm.projectName},on:{"close":_vm.onClose}})],1),_c('transition',{attrs:{"mode":"out-in","name":"route"}},[_c('router-view',{key:_vm.componentKey})],1),(_vm.$route.meta.footer !== false)?_c('FooterComponent',{attrs:{"navigation":_vm.items,"cookie-manager":_vm.CM}}):_vm._e(),_c('AssetComponent'),(_vm.isWebmegler)?_c('Toolbar'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }