<template>
  <div class="openhouse">
    <h2 class="openhouse__title">{{ $tc('openhouse', 2) }}</h2>
    <h4 v-if="items.length">Visningstider</h4>
    <div class="openhouse__list" :class="{ 'openhouse__list--scroll': items.length > 4 }">
      <div class="openhouse__item" v-for="item in items" :key="item.id">
        <div class="openhouse__content row wrap">
          <!-- Teste med Dddd eller uppcaseFirst util ? -->
          <div class="openhouse__date">
            {{ item.from | Moment('dddd D. MMMM') }}
          </div>
          <div class="openhouse__time">
            {{ $t('clockAt') }} {{ item.from | Moment('HH.mm') }} -
            {{ item.to | Moment('HH.mm') }}
          </div>
        </div>
        <div v-if="$path('references.length', item)" class="openhouse__references">
          {{
            $t('openhouseAtValue', {
              value: `${$tc('unit', item.references.length).toLowerCase()} ${item.references
                .map(r => r.number)
                .join(', ')}`,
            })
          }}
        </div>
      </div>
      <h4 v-if="!items || !items.length" class="openhouse__fallback openhouse__item">
        {{ $t('openhouseOnRequest') }}
      </h4>
    </div>

    <div class="openhouse__buttons">
      <ButtonComponent
        tag="a"
        :href="leadUrl"
        target="_blank"
        label="Meld interesse"
        theme="primary"
      />
      <ButtonComponent
        tag="a"
        :href="openhouseUrl"
        target="_blank"
        label="Visningspåmelding"
        theme="secondary"
      />
    </div>
  </div>
</template>

<script>
import { Moment } from '@/filters'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    items() {
      return this.data.content || []
    },

    leadUrl() {
      return this.data.leadUrl
    },

    openhouseUrl() {
      return this.data.openhouseUrl
    },
  },
  filters: {
    Moment,
  },
  components: {},
}
</script>

<style lang="scss">
.openhouse {
  &__list {
    &--scroll {
      max-height: 320px;
      overflow-y: scroll;
      @include scrollShadow();
    }

    display: grid;
    grid-gap: 0.5rem;
  }

  &__fallback {
    margin-bottom: 0.5rem;
  }

  &__item {
    // padding: 1.5rem;
    // border: 1px solid $border-color;

    // border-radius: $border-radius;

    // display: flex;
    // align-items: center;

    // svg {
    //   margin-right: 1rem;
    // }
  }

  &__content {
    @include gap(2px);
  }

  &__date {
    // font-weight: bold;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__time {
    // opacity: 0.6;
  }

  &__references {
    opacity: 0.7;
  }

  &__message,
  &__references {
    font-size: 0.9em;
    margin-top: 2px;
  }
  &__buttons {
    margin-top: 2rem;

    display: flex;
    gap: 1.5rem;

    @include respond-below('phone') {
      flex-direction: column;
      gap: 1rem;
    }
  }
}
</style>
