import { library } from '@fortawesome/fontawesome-svg-core'

/* Solid icons */
import { faTh as fasTh } from '@fortawesome/pro-solid-svg-icons/faTh'
import { faExclamationCircle as fasExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faArrowRight as fasArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { faArrowLeft as fasArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'
import { faMapPin as fasMapPin } from '@fortawesome/pro-solid-svg-icons/faMapPin'
import { faChevronCircleUp as fasChevronCircleUp } from '@fortawesome/pro-solid-svg-icons/faChevronCircleUp'
import { faChevronCircleDown as fasChevronCircleDown } from '@fortawesome/pro-solid-svg-icons/faChevronCircleDown'
import { faChevronDown as fasChevroneDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronUp as fasChevroneUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { faExternalLink as fasExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink'
import { faQuestion as fasQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion'
import { faPlane as fasPlane } from '@fortawesome/pro-solid-svg-icons/faPlane'
import { faWalking as fasWalking } from '@fortawesome/pro-solid-svg-icons/faWalking'
import { faCarSide as fasCarSide } from '@fortawesome/pro-solid-svg-icons/faCarSide'
import { faSubway as fasSubway } from '@fortawesome/pro-solid-svg-icons/faSubway'
import { faGraduationCap as fasGraduationCap } from '@fortawesome/pro-solid-svg-icons/faGraduationCap'
import { faSchool as fasSchool } from '@fortawesome/pro-solid-svg-icons/faSchool'
import { faTrain as fasTrain } from '@fortawesome/pro-solid-svg-icons/faTrain'
import { faTram as fasTram } from '@fortawesome/pro-solid-svg-icons/faTram'
import { faBus as fasBus } from '@fortawesome/pro-solid-svg-icons/faBus'
import { faShip as fasShip } from '@fortawesome/pro-solid-svg-icons/faShip'
import { faDumbbell as fasDumbbell } from '@fortawesome/pro-solid-svg-icons/faDumbbell'
import { faShoppingBag as fasShoppingBag } from '@fortawesome/pro-solid-svg-icons/faShoppingBag'
import { faFutbol as fasFutbol } from '@fortawesome/pro-solid-svg-icons/faFutbol'
import { faMedkit as fasMedkit } from '@fortawesome/pro-solid-svg-icons/faMedkit'
import { faWineBottle as fasWineBottle } from '@fortawesome/pro-solid-svg-icons/faWineBottle'
import { faShoppingCart as fasShoppingCart } from '@fortawesome/pro-solid-svg-icons/faShoppingCart'
import { faChevronRight as fasChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChevronLeft as fasChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'

library.add(
  fasTh,
  fasExclamationCircle,
  fasArrowRight,
  fasArrowLeft,
  fasMapPin,
  fasChevronCircleUp,
  fasChevronCircleDown,
  fasChevroneDown,
  fasChevroneUp,
  fasExternalLink,
  fasQuestion,
  fasPlane,
  fasWalking,
  fasCarSide,
  fasGraduationCap,
  fasSchool,
  fasTrain,
  fasSubway,
  fasTram,
  fasBus,
  fasShip,
  fasDumbbell,
  fasShoppingBag,
  fasFutbol,
  fasMedkit,
  fasWineBottle,
  fasShoppingCart,
  fasPlus,
  fasChevronRight,
  fasChevronLeft,
)

/* Regular icons */
import { faArrowDown as farArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown'
import { faArrowUp as farArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp'
import { faArrowRight as farArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight'
import { faArrowLeft as farArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import { faAngleDown as farAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown'
import { faPen as farPen } from '@fortawesome/pro-regular-svg-icons/faPen'
import { faExternalLink as farExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink'
import { faShareSquare as farShareSquare } from '@fortawesome/pro-regular-svg-icons/faShareSquare'
import { faAddressCard as farAddressCard } from '@fortawesome/pro-regular-svg-icons/faAddressCard'
import { faLockAlt as farLockAlt } from '@fortawesome/pro-regular-svg-icons/faLockAlt'
import { faFileAlt as farFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt'
import { faPhone as farPhone } from '@fortawesome/pro-regular-svg-icons/faPhone'
import { faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'

library.add(
  farArrowDown,
  farArrowRight,
  farArrowLeft,
  farPen,
  farArrowUp,
  farAngleDown,
  farExternalLink,
  farShareSquare,
  farAddressCard,
  farLockAlt,
  farFileAlt,
  farPhone,
  farEnvelope,
)

/* Light icons */
import { faExclamationCircle as falExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle'
import { faCalendar as falCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faEnvelope as falEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faMobile as falMobile } from '@fortawesome/pro-light-svg-icons/faMobile'
import { faPlus as falPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faRulerTriangle as falRulerTriangle } from '@fortawesome/pro-light-svg-icons/faRulerTriangle'
import { faTag as falTag } from '@fortawesome/pro-light-svg-icons/faTag'
import { faSortNumericUp as falSortNumericUp } from '@fortawesome/pro-light-svg-icons/faSortNumericUp'
import { faCheckSquare as falCheckSquare } from '@fortawesome/pro-light-svg-icons/faCheckSquare'
import { faToggleOn as falToggleOn } from '@fortawesome/pro-light-svg-icons/faToggleOn'
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faPalette as falPalette } from '@fortawesome/pro-light-svg-icons/faPalette'
import { faAngleUp as falAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp'
import { faFont as falFont } from '@fortawesome/pro-light-svg-icons/faFont'
import { faAngleDown as falAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown'
import { faPaintRoller as falPaintRoller } from '@fortawesome/pro-light-svg-icons/faPaintRoller'
import { faSort as falSort } from '@fortawesome/pro-light-svg-icons/faSort'
import { faAngleRight as falAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { faChevronDown as falChevroneDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faChevronUp as falChevroneUp } from '@fortawesome/pro-light-svg-icons/faChevronUp'
import { faBars as falBars } from '@fortawesome/pro-light-svg-icons/faBars'

library.add(
  falExclamationCircle,
  falCalendar,
  falEnvelope,
  falMobile,
  falPlus,
  falRulerTriangle,
  falTag,
  falSortNumericUp,
  falToggleOn,
  falCheckSquare,
  falTimes,
  falPalette,
  falAngleUp,
  falAngleDown,
  falFont,
  falPaintRoller,
  falSort,
  falAngleRight,
  falChevroneDown,
  falChevroneUp,
  falBars,
)

/* Brand icons */
import { faFacebook as fabFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faFacebookSquare as fabFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faInstagram as fabInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faLinkedin as fabLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faTwitter as fabTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'

library.add(fabFacebook, fabInstagram, fabLinkedin, fabTwitter, fabFacebookSquare)

/* Duotone icons */
import { faSyncAlt as fadSyncAlt } from '@fortawesome/pro-duotone-svg-icons/faSyncAlt'
import { faAngleDown as fadAngleDown } from '@fortawesome/pro-duotone-svg-icons/faAngleDown'
import { faSpinnerThird as fadSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird'
import { faFilePdf as fadFilePdf } from '@fortawesome/pro-duotone-svg-icons/faFilePdf'
import { faFileAlt as fadFileAlt } from '@fortawesome/pro-duotone-svg-icons/faFileAlt'
import { faFileImage as fadFileImage } from '@fortawesome/pro-duotone-svg-icons/faFileImage'

library.add(fadSyncAlt, fadAngleDown, fadSpinnerThird, fadFilePdf, fadFileAlt, fadFileImage)
