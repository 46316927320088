<template>
  <img class="pm-logo" src="https://templates.kvass.no/custom-pm-1/assets/logo_small.png" />
</template>

<script>
export default {}
</script>

<style lang="scss">
.pm-logo {
  width: 100%;
  max-width: 200px;
  aspect-ratio: 16/9;
  object-fit: contain;
  // from kpb grid
  justify-self: center;
}
</style>
