var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-navbar"},[(_vm.logo)?_c('a',{staticClass:"elder__navigation-logo modal-navbar__logo",attrs:{"aria-label":"Gå til privatmegleren.no","href":"https://privatmegleren.no"}},[_c('img',{staticClass:"elder__navigation-logo-image model-navbar__logo-img",attrs:{"alt":_vm.title,"src":_vm.logo}})]):_vm._e(),(!_vm.hideButton)?_c('ButtonComponent',{staticClass:"modal-navbar__close-button",attrs:{"icon":['fal', 'times']},on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e(),_c('ul',{staticClass:"modal-navbar__items",style:(!_vm.hideButton ? 'margin-top: 3em' : '')},[_c('Submenu',{attrs:{"label":"Kjøpe","items":_vm.buyItems,"value":_vm.value},on:{"lastOpenedValue":_vm.toggleMenu}}),_c('Submenu',{attrs:{"label":"Selge","items":_vm.sellItems,"value":_vm.value},on:{"lastOpenedValue":_vm.toggleMenu}}),_c('Submenu',{attrs:{"label":"Nybygg","items":_vm.nybyggItems,"value":_vm.value},on:{"lastOpenedValue":_vm.toggleMenu}}),_vm._m(0),_vm._m(1),_c('Submenu',{attrs:{"label":"Kontor og megler","items":_vm.officeItems,"value":_vm.value},on:{"lastOpenedValue":_vm.toggleMenu}}),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"aria-label":"Næring"}},[_c('a',{attrs:{"href":"https://privatmegleren.no/naering"}},[_vm._v("Næring")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"aria-label":"Fritidseiendom"}},[_c('a',{attrs:{"href":"https://privatmegleren.no/fritid"}},[_vm._v("Fritidseiendom")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"aria-label":"Digital boligannonsering"}},[_c('a',{attrs:{"href":"https://privatmegleren.no/kampanje/presisjon"}},[_vm._v("Digital boligannonsering")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"aria-label":"Kjøpsmegling"}},[_c('a',{attrs:{"href":"https://privatmegleren.no/kjop"}},[_vm._v("Kjøpsmegling")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"aria-label":"Stillinger"}},[_c('a',{attrs:{"href":"https://privatmegleren.no/stillinger"}},[_vm._v("Stillinger")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{attrs:{"aria-label":"Om oss"}},[_c('a',{attrs:{"href":"https://privatmegleren.no/om-oss"}},[_vm._v("Om oss")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"privacy-link",attrs:{"aria-label":"Personvern"}},[_c('a',{attrs:{"href":"https://privatmegleren.no/personvern"}},[_vm._v("Personvern")])])
}]

export { render, staticRenderFns }