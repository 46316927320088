<template>
  <img class="horizontal-line" />
</template>

<script>
export default {}
</script>

<style lang="scss">
.horizontal-line {
  width: 100%;
  //height: 0.0625rem;
  height: 1px;
  overflow: hidden;
  border: none;
  background-color: var(--primary);

  background: url('https://templates.kvass.no/custom-pm-1/assets/horizontal_line.svg') no-repeat;

  margin-block: 2rem;
}
</style>
