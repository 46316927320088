<template>
  <img class="vertical-line" />
</template>

<script>
export default {}
</script>

<style lang="scss">
.vertical-line {
  width: 1px;
  //   padding: 2rem;

  height: 100%;
  overflow: hidden;
  border: none;
  background-color: var(--primary);
  background: url('https://templates.kvass.no/custom-pm-1/assets/vertical_line.svg') no-repeat;

  // background: linear-gradient(
  //   0deg,
  //   rgba(131, 70, 40, 1) 0%,
  //   rgba(131, 70, 40, 1) 0%,
  //   rgba(130, 69, 40, 1) 6%,
  //   rgba(255, 219, 185, 1) 30%,
  //   rgba(181, 86, 34, 1) 48%,
  //   rgba(203, 154, 112, 1) 64%,
  //   rgba(154, 94, 61, 1) 90%
  // );

  //   margin-block: 2rem;
}
</style>
