<template>
  <div class="modal-navbar">
    <a
      v-if="logo"
      class="elder__navigation-logo modal-navbar__logo"
      aria-label="Gå til privatmegleren.no"
      href="https://privatmegleren.no"
    >
      <img :alt="title" :src="logo" class="elder__navigation-logo-image model-navbar__logo-img" />
    </a>

    <ButtonComponent
      v-if="!hideButton"
      class="modal-navbar__close-button"
      :icon="['fal', 'times']"
      @click="$emit('close')"
    />

    <ul class="modal-navbar__items" :style="!hideButton ? 'margin-top: 3em' : ''">
      <Submenu label="Kjøpe" :items="buyItems" @lastOpenedValue="toggleMenu" :value="value" />
      <Submenu label="Selge" :items="sellItems" @lastOpenedValue="toggleMenu" :value="value" />
      <Submenu label="Nybygg" :items="nybyggItems" @lastOpenedValue="toggleMenu" :value="value" />

      <li aria-label="Næring">
        <a href="https://privatmegleren.no/naering">Næring</a>
      </li>

      <li aria-label="Fritidseiendom">
        <a href="https://privatmegleren.no/fritid">Fritidseiendom</a>
      </li>

      <Submenu
        label="Kontor og megler"
        :items="officeItems"
        @lastOpenedValue="toggleMenu"
        :value="value"
      />
      <li aria-label="Digital boligannonsering">
        <a href="https://privatmegleren.no/kampanje/presisjon">Digital boligannonsering</a>
      </li>

      <li aria-label="Kjøpsmegling">
        <a href="https://privatmegleren.no/kjop">Kjøpsmegling</a>
      </li>
      <li aria-label="Stillinger">
        <a href="https://privatmegleren.no/stillinger">Stillinger</a>
      </li>

      <li aria-label="Om oss">
        <a href="https://privatmegleren.no/om-oss">Om oss</a>
      </li>

      <li aria-label="Personvern" class="privacy-link">
        <a href="https://privatmegleren.no/personvern">Personvern</a>
      </li>
    </ul>
  </div>
</template>

<script>
import Submenu from './SubMenu.vue'
import { animate, inView, stagger } from 'motion'

export default {
  props: {
    logo: String,
    title: String,
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: '',
    }
  },

  computed: {
    buyItems() {
      return [
        { label: 'Kjøpe eiendom', href: 'https://privatmegleren.no/kjope-bolig' },
        { label: 'Fritidseiendom', href: 'https://privatmegleren.no/fritid' },
        { label: 'Finansiering', href: 'https://privatmegleren.no/finansiering' },
        { label: 'Hjelp til kjøp', href: 'https://privatmegleren.no/kjop' },
        { label: 'Boligbytte - Hjelp med flytting', href: 'https://privatmegleren.no/boligbytte' },
        { label: 'Registrer ditt eiendomssøk', href: 'https://privatmegleren.no/boligsok' },
      ]
    },

    sellItems() {
      return [
        { label: 'Bruktbolig', href: 'https://privatmegleren.no/kontorer?alle' },
        { label: 'Nybygg', href: 'https://privatmegleren.no/kontorer?nybygg' },
        { label: 'Fritidseiendom', href: 'https://privatmegleren.no/fritid' },
        {
          label: 'Bestill e-takst/verdivurdering',
          href: 'https://privatmegleren.no/verdivurdering',
        },
        { label: 'Boligbytte - Hjelp med flytting', href: 'https://privatmegleren.no/boligbytte' },
        { label: 'Vår leveranse', href: 'https://privatmegleren.no/leveransebeskrivelse' },
      ]
    },
    nybyggItems() {
      return [
        { label: 'Om nybygg', href: 'https://privatmegleren.no/nybygg' },
        { label: 'Nybygg til salgs', href: 'https://privatmegleren.no/kjope-bolig?sd=NYBYGG' },
        { label: 'Finn megler', href: 'https://privatmegleren.no/kontorer?nybygg' },
        {
          label: 'Finansiering',
          href: 'https://privatmegleren.no/finansiering',
        },
        { label: 'Hjelp til kjøp', href: 'https://privatmegleren.no/kjop' },
        { label: 'Registrer ditt eiendomssøk', href: 'https://privatmegleren.no/boligsok' },
        { label: 'Notis (Fordelstjeneste)', href: 'https://notis.privatmegleren.no/' },
      ]
    },

    officeItems() {
      return [
        { label: 'Søk megler eller kontor', href: 'https://privatmegleren.no/kontorer?alle' },
        { label: 'Søk i nærheten', href: 'https://privatmegleren.no/kontorer/inarheten' },
      ]
    },
  },

  methods: {
    toggleMenu(label) {
      this.value = label
    },

    async initAnimation() {
      inView('.modal-navbar__items', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })
    },
  },

  mounted() {
    this.initAnimation()
  },

  components: {
    Submenu,
  },
}
</script>

<style lang="scss">
.modal-navbar {
  .elder__navigation-logo-image {
    max-height: 50px !important;
    height: 50px !important;
  }

  a {
    text-decoration: none;
  }

  &__logo {
    position: absolute;
    top: 20px;
    left: 1.5rem;
    &-img {
    }
  }

  &__close-button {
    height: 50px;
    font-size: 2rem;
    position: absolute;
    top: 20px;
    right: 35px;
  }

  .elder-button {
    border: none;
    background-color: transparent;
    box-shadow: none !important;
    &:before {
      display: none;
    }
    &__label,
    &__icon {
      padding: 0 !important;
    }
  }

  .sub-menu__button {
    width: 100%;
    justify-content: space-between;

    .elder-button__label {
      text-align: left;
    }
    .elder-button__icon {
      margin-left: 0.5em;
      color: var(--primary);
    }
  }

  &__items {
    opacity: 0;
    list-style: none;
    padding: 0px;
    margin: 0px;
    // margin-top: 3em;

    transform: translateY(-10px);
  }

  li {
    font-size: 1.6875rem;
    text-align: left;
    pointer-events: all;
    margin-bottom: 0.5em;
  }

  .privacy-link {
    font-size: 1rem;
    margin-top: 2em;
  }

  .sub-menu {
    list-style: none;
    padding: 0px;
    margin: 0px;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 0.5em;

    li {
      font-size: 1rem;
    }
  }
}
</style>
