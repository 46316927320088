import { GraphQLClient } from 'graphql-request'
import Queries from './graphql/queries'

let urlNode = document.head.querySelector('[property="og:url"]')
let urlObj = urlNode ? new URL(urlNode.getAttribute('content').split('/residential')[0]) : null
let endpoint = ''

if (location.host === 'privatmegleren.no' && urlObj) {
  if (urlObj.hostname.split('.').length === 2) endpoint = `https://www.${urlObj.hostname}`
  else endpoint = `https://` + urlObj.hostname
}

function getFlatfinder(payload) {
  return new GraphQLClient(endpoint + '/api/graphql', {
    headers: { 'accept-language': document.querySelector('html').getAttribute('lang') },
  })
    .request(Queries.Flatfinder, payload)
    .then(data => data.Flatfinder)
}

function getFlatfinderMetadata(payload) {
  return new GraphQLClient(endpoint + '/api/graphql', {
    headers: { 'accept-language': document.querySelector('html').getAttribute('lang') },
  }).request(Queries.FlatfinderMetadata, payload)
}

export { getFlatfinder, getFlatfinderMetadata }
