<template>
  <Loader
    v-if="preview || webmegler"
    :value="loading"
    theme="overlay"
    :delay="0"
    message="Kommuniserer med fagsystem, dette kan ta opp til flere minutter..."
    :class="[{ 'toolbar-loader': loading }, { 'toolbar-loader-background': !manualUpdate }]"
  >
    <div class="toolbar toolbar--open">
      <div class="toolbar__publish" v-if="preview">
        <ButtonComponent
          :label="'Publiser prosjektet'"
          @click="publish"
          :promise="publishPromise"
          :state-timeout="60000"
          loading-label="Publiserer..."
          success-label="Publisert!"
          error-label="Feil ved publisering"
        />
      </div>

      <p v-if="flatfinderText" class="toolbar__flatfinder-text">
        Boligvelger kommer fortløpende
      </p>

      <div class="toolbar__update">
        <ButtonComponent label="Oppdater siden" @click="updateProject" />
        <p class="toolbar__small-text">Henter nyeste informasjon fra fagsystem</p>
      </div>
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { eventBus as AssetBus } from '@/App.vue'

export default {
  data() {
    return {
      publishPromise: null,
      //  updatePromise: null,
      flatfinderPromise: null,
      loading: false,

      manualUpdate: false,
    }
  },
  computed: {
    ...mapState('Project', ['item']),

    preview() {
      return this.$route.query['previewToken'] && this.project !== '64a508f1dbfc3517aace04cd'
    },

    token() {
      return this.$path('item.customFields.token', this)
    },

    account() {
      return this.$path('item.customFields.account', this)
    },

    project() {
      return this.$path('item.id', this)
    },

    integration() {
      return this.$path('item.customFields.integration', this)
    },

    webmegler() {
      return this.$route.query['update']
    },

    flatfinderText() {
      return (
        'privatmegleren-project' in (this.$path('item.customFields', this) || {}) &&
        !this.$path('item.flatfinders.length', this)
      )
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),

    async isInstalling(interval = 5000) {
      if (!this.account || !this.integration || !this.token) return

      this.loading = true

      const data = await fetch(
        `${this.account}/api/integration/${this.integration}/callbacks/is-installing`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}),
        },
      )
        .then(res => {
          if (res.status === 404) return false

          if (res.status !== 200) return true
          return res.json()
        })
        .catch(_ => false)

      if (data === true) return setTimeout(() => this.isInstalling(interval), interval)

      AssetBus.emit('update', true)

      this.loading = false
    },
    async updateProject() {
      this.manualUpdate = true

      await fetch(`${this.account}/api/integration/${this.integration}/callbacks/updateProject`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          project: this.project,
          webmegler: this.$path('item.customFields.webmegler', this)
            ? this.$path('item.customFields.webmegler', this)
            : false,
          isProject: 'privatmegleren-project' in (this.$path('item.customFields', this) || {}),
        }),
      }).catch(err => console.error(err))

      return this.isInstalling()
    },

    publish() {
      this.publishPromise = fetch(
        `${this.account}/api/integration/${this.integration}/callbacks/publish`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ project: this.project }),
        },
      ).catch(err => console.error(err))
    },
  },
  created() {
    this.fetch().then(() => {
      if (this.preview || this.webmegler) return this.isInstalling()
    })
  },
}
</script>

<style lang="scss">
.toolbar-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1006;

  &-background {
    .elder-loader__element {
      background-color: rgba(
        0,
        0,
        0,
        var(--vue-elder-loader-theme-overlay-transparency, 0.8)
      ) !important;

      // background-image: url('/assets/loading.png');
    }
  }

  .elder-loader__element {
    background-color: rgba(0, 0, 0, var(--vue-elder-loader-theme-overlay-transparency, 0.5));
  }

  // background-color: var(--secondary);
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 1000001;
  // height: 100vh;
  // width: 100%;
  // .elder-loader__element {
  //   background-color: var(--secondary);
  //   color: var(--primary);
  //   height: 100vh;
  // }
  // .elder-loader__element-content {
  //   max-height: 100%;
  // }
  .elder-loader__element-content-inner {
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
  }
}

.toolbar {
  font-size: 1em;

  background-color: var(--primary);
  color: var(--primary-contrast);

  padding: 1rem 2rem;
  padding-top: 0;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  font-family: 'Arial';

  position: fixed;
  z-index: 1006;
  top: 90px;
  right: 0;

  text-align: center;

  & > div:first-of-type {
    p {
      margin-top: 0;
    }
  }

  &__flatfinder-text {
    margin: 0 auto;

    max-width: 200px;
    text-align: center;
    font-size: 12px;

    @include respond-below('phone') {
      max-width: 100px;
    }
  }

  &__small-text {
    margin: 0 auto;
    padding-top: 0.3rem;
    max-width: 200px;
    text-align: center;
    font-size: 10px;

    @include respond-below('phone') {
      max-width: 100px;
    }
  }

  &__publish {
  }
  &__update {
  }

  &__flatfinder {
  }

  .elder-button {
    &__label {
      padding: 0.5rem;
      font-size: 0.7rem;
    }
  }

  &--open {
    padding: 0.75rem;
  }
}
</style>
