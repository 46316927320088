import { GraphQLClient } from 'graphql-request'
import { getCookie } from '@/utils'
import Queries from './graphql/queries'
import Mutations from './graphql/mutations'

let urlNode = document.head.querySelector('[property="og:url"]')
let urlObj = urlNode ? new URL(urlNode.getAttribute('content').split('/residential')[0]) : null
let endpoint = ''

if (location.host === 'privatmegleren.no' && urlObj) {
  if (urlObj.hostname.split('.').length === 2) endpoint = `https://www.${urlObj.hostname}`
  else endpoint = `https://` + urlObj.hostname
}
  

class API {
  constructor() {
    let params = new URLSearchParams(location.search)
    this.project = getCookie('kvass.project') || params.get('project-id')
    // this.projectType = getCookie('kvass.projectType') || 'default'
    this.projectType = 'default'
  }

  get logo() {
    return 'https://templates.kvass.no/custom-pm-1/assets/logo.png'
    return `/api/project/${this.project}/logo`
  }

  get logoInverted() {
    return 'https://templates.kvass.no/custom-pm-1/assets/logo.png'
    return `/api/project/${this.project}/logo-inverted`
  }

  get favicon() {
    return 'https://templates.kvass.no/custom-pm-1/assets/favicon.png'
    return `/api/project/${this.project}/favicon`
  }

  get projectId() {
    return this.project
  }

  request(...args) {
    return new GraphQLClient(endpoint + '/api/graphql', {
      headers: {
        'accept-language': document.querySelector('html').getAttribute('lang'),
      },
    }).request(...args)
  }

  getProject(id) {
    if (!id) id = this.project

    let getQuery = () => {
      switch (this.projectType) {
        case 'singular':
          return Queries.ProjectResidential
        default:
          return Queries.Project
      }
    }

    return this.request(getQuery(), { id }).then(data => data.Project)
  }

  getPosts(id) {
    if (!id) id = this.project
    return this.request(Queries.Posts, { id }).then(data => data.Posts)
  }

  getResidential(id) {
    return this.request(Queries.Residential, { id }).then(data => data.Residential)
  }

  getRoot(id) {
    if (!id) id = this.project

    return this.request(Queries.Root, { id }).then(data => data.Project)
  }

  createLead(data) {
    return this.request(Mutations.LeadCreate, { data }).then(data => data.LeadCreate)
  }

  getFlatfinder(id) {
    return this.request(Queries.Flatfinder, { id }).then(data => data.Flatfinder)
  }
}

export default new API()
