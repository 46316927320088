<template>
  <section class="demographics">
    <h2 class="demographics-section-title">Demografi</h2>

    <div class="demographics__wrapper">
      <div class="demographics__charts">
        <Doughnut
          v-for="(item, index) in value"
          :key="index"
          :data="[Math.floor(parseFloat(item.value)), Math.floor(100 - parseFloat(item.value))]"
          :title="item.name"
        />
      </div>
      <ul class="demographics__list">
        <li class="demographics__point" v-for="(item, index) in value" :key="index">
          {{ item.value }} % {{ item.name }}
        </li>
        <li class="demographics__point demographics__point--button" v-if="interactiveUrl">
          <ButtonComponent
            label="Interaktiv versjon"
            theme="secondary"
            tag="a"
            target="_blank"
            :icon="['fas', 'external-link']"
            :href="interactiveUrl"
          />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Doughnut from '../graphs/Doughnut.vue'

export default {
  props: {
    value: Array,
    interactiveUrl: String,
  },
  components: {
    Doughnut,
  },
}
</script>

<style lang="scss">
.demographics {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 2rem;

    grid-template-areas: 'chart list';

    @include respond-below('tablet') {
      grid-template-columns: 1fr;
      grid-template-areas:
        'list'
        'chart';
    }
  }

  &__charts {
    grid-area: chart;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem 2rem;

    @include respond-below('desktop') {
      grid-template-columns: 1fr 1fr;
    }

    @include respond-below('phone') {
      grid-template-columns: 1fr;
    }
  }
  &__list {
    padding: 0;
    grid-area: list;
    margin-top: 0;
    // padding: 0;
  }
  &__point {
    padding: 0.2rem;
    list-style-type: none;
    &--button {
      padding-top: 2rem;
      @include respond-below('phone') {
        .elder-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
