<template>
  <li>
    <ButtonComponent @click="onClick" :label="label" :icon="iconComp" class="sub-menu__button" />
    <ul class="sub-menu" v-if="isOpen && this.value === label">
      <li v-for="(item, index) in items" :key="index + item">
        <a :aria-label="item.label" :href="item.href">{{ item.label }}</a>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    label: String,
    items: Array,
    value: String,
  },
  data() {
    return {
      open: false,
    }
  },

  methods: {
    onClick() {
      if (this.open && this.value !== this.label) this.open = false

      this.open = !this.open
      this.$emit('lastOpenedValue', this.label)
    },
  },

  computed: {
    isOpen() {
      return this.open && this.value === this.label
    },

    iconComp() {
      return this.isOpen ? ['fal', 'chevron-up'] : ['fal', 'chevron-down']
    },
  },
}
</script>

<style lang="scss"></style>
