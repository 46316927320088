<template>
  <section class="population" v-if="content">
    <h2 class="demographics-section-title">Familiesammensetning</h2>
    <div class="population__label" v-for="(label, index) in labels" :key="index">
      <div class="population__label-box" :class="`population__label-box--${index}`"></div>
      <div class="population__label-value">{{ label }}</div>
    </div>

    <div class="population__charts">
      <div class="population__item" v-for="(item, index) in content" :key="index">
        <div
          class="population__barchart-wrapper"
          :style="{
            height: `200px`,
          }"
        >
          <div
            class="population__barchart"
            :class="[`population__barchart--${idx}`]"
            v-for="(value, idx) in item.data"
            :key="idx"
            :style="{
              height: `${value * multiplier}%`,
            }"
          >
            <span class="population__barchart-value">{{ value }} %</span>
          </div>
        </div>
        <p class="population__barchart-label">
          {{ item.label }}
          <br />
          {{ item.age }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    profile: Object,
  },

  computed: {
    multiplier() {
      const number = Math.max(...this.content.map(c => c.data).flat())

      return 100 / number
    },

    labels() {
      return [this.neighborhood, this.city]
    },

    neighborhood() {
      return this.$path('nabolag.name', this.profile) || 'Nabolag'
    },

    city() {
      return this.$path('nabolag.cityName', this.profile) || 'By/kommune'
    },

    content() {
      if (!this.profile) return []

      return [this.children, this.youth, this.youngAdults, this.adults, this.elder]
    },

    children() {
      return {
        label: 'Barn',
        age: '(0-12)',
        data: this.getData('barn_prosent', ['ALDU_5', 'ALD6_12']),
      }
    },

    youth() {
      return {
        label: 'Ungdom',
        age: '(13-18)',
        data: this.getData('ungdom_prosent', ['ALD13_15', 'ALD16_18']),
      }
    },

    youngAdults() {
      return {
        label: 'Ungdom',
        age: '(19-34)',
        data: this.getData('ung_prosent', ['ALD19_23', 'ALD24_34']),
      }
    },

    adults() {
      return {
        label: 'Voksne',
        age: '(35-64)',
        data: this.getData('voksen_prosent', ['ALD35_44', 'ALD45_54', 'ALD55_64']),
      }
    },

    elder() {
      return {
        label: 'Eldre',
        age: '(64 - )',
        data: this.getData('eldre_prosent', ['ALD65_74', 'ALD75_84', 'ALD85_O']),
      }
    },
  },

  methods: {
    getData(property, fallbackAges = []) {
      let neighborValue = this.$path(`demographics.ssb.grunnkrets.${property}`, this.profile)
      let cityValue = this.$path(`demographics.ssb.kommune.${property}`, this.profile)

      if (!neighborValue || !cityValue) {
        neighborValue = fallbackAges.reduce((acc, curr) => {
          return (
            acc +
            parseFloat(
              this.$path(`nabolag_demografics.demografics.nabolag.${curr}.percent`, this.profile),
            )
          )
        }, 0)
        cityValue = fallbackAges.reduce((acc, curr) => {
          return (
            acc +
            parseFloat(
              this.$path(`nabolag_demografics.demografics.by.${curr}.percent`, this.profile),
            )
          )
        }, 0)
      }

      return [Math.floor(parseFloat(neighborValue)), Math.floor(parseFloat(cityValue))]
    },
  },
}
</script>

<style lang="scss">
.population {
  &__label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    &-box {
      width: 1.25rem;
      height: 1.25rem;

      &--0 {
        border: 1px solid var(--primary);
        background: rgb(75, 75, 75);
      }
      &--1 {
        background: var(--primary);
      }
    }
    &-label {
    }
  }

  &__charts {
    padding-block: 6rem 3rem;
    display: flex;
    justify-content: space-evenly;

    @include respond-below('tablet') {
      padding-block: 3rem 3rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4rem 0rem;
    }
  }
  &__item {
  }

  &__barchart-label {
    text-align: center;
    font-size: 0.7rem;
  }
  &__barchart-wrapper {
    display: flex;
    gap: 1rem;
    align-items: end;
    justify-content: center;
  }

  &__barchart {
    width: 2em;
    position: relative;

    &--0 {
      background: rgb(75, 75, 75);
    }
    &--1 {
      background: var(--primary);
    }
    &-value {
      white-space: nowrap;
      font-size: 1rem;
      // font-family: var(--third-font);
      position: absolute;
      top: -1em;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
