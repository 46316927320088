<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <Blocks
        ref="blocks"
        :blocks="config"
        :custom-components="customComponents"
        :key="componentKey"
      />
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import CustomComponents from '@/components/Custom'
import Accordion from '@/components/Accordion'
import Demographics from '@/components/Demographics'
import ResidentialsList from '@/components/ResidentialsList'
import ReadMore from '@/components/ReadMore'
import FlatfinderCustom from '@/components/FlatfinderCustom'
import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField } from '@/utils'
import { Blocks } from '@kvass/pagebuilder'
import Config from '../config/project'

import { animate, inView, stagger } from 'motion'

import { eventBus as AssetBus } from '@/App.vue'

export default {
  mixins: [BrowserApiMixin('project')],

  data() {
    return {
      componentKey: 0,
    }
  },

  computed: {
    ...mapState('Project', ['promises', 'item']),
    config() {
      return Config.call(this)
    },
    customComponents() {
      return {
        ...CustomComponents,
        ...ProjectComponents,
        Accordion,
        Demographics,
        ResidentialsList,
        ReadMore,
        FlatfinderCustom,
      }
    },
  },

  methods: {
    ...mapActions('Project', ['fetch', 'forceFetchProject']),
    GetCustomField,
    async initAnimation() {
      animate(
        '.hero__content ',
        { opacity: 1, transform: 'none' },
        { duration: 0.8, delay: stagger(0.3) },
      )

      inView('.kvass-image-text__item', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })

      inView('.key-info', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { delay: 0, duration: 0.8 })
      })

      inView('.total-cost', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { delay: 0, duration: 0.8 })
      })

      inView('.documents-section', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { delay: 0, duration: 0.8 })
      })

      inView('.nordea-section__content-wrapper', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { delay: 0, duration: 0.8 })
      })

      inView('.nordea-logo', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { delay: 0, duration: 0.8 })
      })

      inView('.sale__content', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { delay: 0, duration: 0.8 })
      })

      inView('.pm-logo', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { delay: 0, duration: 0.8 })
      })

      inView('.openhouse', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { delay: 0, duration: 0.8 })
      })

      inView('.roles', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { delay: 0, duration: 0.8 })
      })

      inView('.horizontal-line', ({ target }) => {
        animate(target, { width: '100%', transform: 'none' }, { duration: 0.8, delay: 0 })
      })

      // const lines = document.querySelectorAll('.demographics-section-title')

      // inView(lines, ({ target }) => {
      //   animate(target, { width: '100%', transform: 'none' }, { duration: 0.8, delay: 0 })
      // })

      inView('.vertical-line', ({ target }) => {
        animate(
          target,
          { height: '100%', transform: 'none' },
          { duration: 0.8, delay: 0.6, easing: 'ease-in-out' },
        )
      })

      inView(
        '.section-info__content',
        ({ target }) => {
          animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
        },
        { margin: '0px 0px -200px 0px' },
      )

      inView('.section-info-image', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })
    },
  },
  created() {
    this.fetch()
  },
  mounted() {
    this.promises.fetch.then(() => setTimeout(() => this.initAnimation(), 100))

    AssetBus.on('update', value => {
      this.forceFetchProject().then(() => (this.componentKey += 1))
    })
  },

  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },

  components: {
    ...ProjectComponents,
    Blocks,
  },
}
</script>

<style lang="scss">
.nav--project-expanded + .elder-loader {
  .cta-banner {
    display: none;
  }
}

.project-page > .kpb-blocks {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }
  // .kvass-image-text__item {
  //   opacity: 0;
  // }

  /* START OF FOR ANIMATIONS */

  .roles-layout,
  .sale__section-layout,
  .nordea-section-layout,
  .key-info-section-layout {
    overflow: hidden;
  }
  .openhouse,
  .sale__content,
  .nordea-section__content-wrapper,
  .documents-section,
  .key-info {
    opacity: 0;
    transform: translateX(-100px);
  }
  .roles,
  .pm-logo,
  .nordea-logo,
  .total-cost {
    opacity: 0;
    transform: translateX(100px);
  }

  /* END OF FOR ANIMATIONS */

  .image-section {
    &__image {
      .kpb-image__image {
        max-height: 50vh;
      }
    }
  }

  // for animation
  .vertical-line {
    height: 0;
  }

  // for animation
  // .demographics-section-title,
  .horizontal-line {
    width: 0;
  }

  .section-map {
    .mapboxgl-ctrl-group {
      background-color: var(--primary);
    }

    .kpb-map__mapbox {
      @include respond-below('phone') {
        --kpb-map-aspect-ratio: 16/9;
      }
    }
  }

  .gallery-section {
  }

  .residentials {
    &__sort {
      //border: 1px solid var(--primary);
      // background: rgba(255, 255, 255, 0.166);
    }
    &__title {
      color: var(--primary);
      @include header-font(2rem);

      @include respond-below('phone') {
        // font-size: 1.25rem;
      }
    }
  }

  .key-info-section {
    &-header {
      h2 {
        margin-bottom: 0;
      }
    }
    &-layout {
      @include respond-above('tablet') {
        // gap: 2rem;
        grid-template-columns: 1fr 1px 1fr;
      }

      .vertical-line {
        display: none;
        @include respond-above('tablet') {
          display: unset;
        }
      }
    }
    &-line {
      &-tablet {
        @include respond-above('tablet') {
          display: none;
        }
      }
    }
  }

  .roles-layout {
    @include respond-below('tablet') {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
  }

  .nordea-section {
    padding-block: 6rem;

    @include respond-below('tablet') {
      padding-block: 4rem;
    }

    @include respond-below('phone') {
      padding-block: 2rem;
    }

    &__content {
      p {
        margin-block: 1rem;
      }
    }
  }

  .sale {
    &__background {
      background-image: url('https://templates.kvass.no/custom-pm-1/assets/city.png') !important;
    }
    &__content {
    }
    &__section {
      @include respond-above('phone') {
        padding-block: 6rem;
      }

      padding-block: 3rem;
    }
  }

  .documents {
    &__background {
      @include respond-above('phone') {
        height: 33em;
      }

      height: 300px;

      background-image: url('https://templates.kvass.no/custom-pm-1/assets/boxes.png') !important;
    }
  }

  .header {
    padding-block: 1rem 2rem;

    &__stats {
      color: var(--primary);
      font-weight: bold;
    }
    &__title {
      h1 {
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }
    &__content {
      @include header-font(1.25rem);

      p {
        margin-top: 0rem;
      }
    }
    &__buttons {
      margin-top: 2rem;
    }
  }

  .hero {
    &__slider {
      $height: calc(80vh + #{$nav-height});

      position: relative;
      height: $height;

      width: 100%;
      background-color: css-alpha('primary', 0.1);
      img {
        object-position: center;
      }
      iframe {
        min-height: 100vh;
      }

      display: flex;
      flex-direction: column;
      @include respond-below('phone') {
        height: calc(50vh + #{$nav-height});
        iframe {
          min-height: 100vh;
        }
      }
    }

    &__stats {
      position: absolute;
      bottom: 2rem;
      left: 2rem;

      background-color: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 0.7);

      // background-color: var(--secondary);
      border: none;

      border-radius: 60px;

      // padding: 0.75em 1.5em;

      @include respond-below('phone') {
        bottom: 1rem;
        left: 1rem;
      }
    }
  }

  .hero__content {
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    bottom: initial;
    right: initial;
    width: 100vw;
    height: 100vh;
    padding-inline: 1rem;

    color: white;
    font-size: clamp(1rem, 1.4rem, 2rem);
    background-color: rgba(black, 0.3);
    z-index: get-layer('cover-overlay');

    &-placement {
      &--center {
        .hero__content-buttons {
          justify-content: center !important;
        }
      }
      &--left {
        align-items: flex-start;
        justify-content: center;

        .kpb-text {
          text-align: left;
          margin-left: 3rem;
          @include respond-below('phone') {
            margin-left: 1rem;
          }
        }
      }
    }
  }

  .hero__content {
    & > * {
      padding: 0rem 1rem;
    }

    h1 {
      margin-bottom: clamp(1rem, 2rem, 3rem);
      font-size: clamp(2.5rem, 3.5rem, 4rem);
      margin-bottom: 1rem;
      line-height: 1.1;
    }
    p {
      margin-top: 0;
    }

    .elder-button__label {
      font-size: 1.1rem;
      padding: 0.9rem 1.25rem;
      @include respond-below('phone') {
        padding: 0.5rem 1rem;

        font-size: 0.8rem;
      }
    }
    .elder-button--icon-right .elder-button__icon {
      padding: 0.9rem 1.25rem 0.9rem 0;
      font-size: 1.1rem;
      @include respond-below('phone') {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
      }
    }
    a {
      margin: 0 0.5rem;
      &[data-cta='primary'] {
        background: white;
        color: black;
        font-weight: 500;
        &:hover {
          background: rgba(white, 0.9);
        }
      }
      &[data-cta='secondary'] {
        font-weight: 500;
        background: transparent;
        color: white;
        border: 1px solid white;
        &:hover {
          background: rgba(black, 0.2);
        }
      }
    }
  }

  .section-residentials {
    .kpb-section__container {
      @include respond-below('phone') {
        padding: 0;
      }
    }
  }

  .section-info-image,
  .section-info {
    @include respond-below('phone') {
      padding: 2rem 1rem;
    }

    &__description {
      @include respond-below('tablet') {
        column-count: 1;
      }

      column-count: 2;
      column-gap: 4rem;

      h2 {
        column-span: all;
      }
      p:first-of-type {
        @include header-font(1.25rem);
        column-span: all;
      }

      white-space: pre-line;

      p {
        margin-top: 0;
      }
    }

    &__container {
      @include respond-below('phone') {
        padding: 0;
      }
    }
  }

  .kpb-blocks {
    .section-info {
      margin-bottom: 1em;

      &__content {
        align-items: center;
      }
    }
    .section-info-image {
      .kpb-section__container {
        @include respond-below('phone') {
          padding: 0;
        }
      }
      .info-image {
        &--frame {
          img {
            border: 3px solid var(--primary);
            padding: 0.4rem;
            background: transparent;
          }
        }
        &--round-edges {
          $radius: 3rem;
          img {
            border-radius: $radius;
          }

          .kvass-media-render-image__caption {
            border-radius: $radius;
          }

          @include respond-below('phone') {
            img {
              border-radius: calc(#{$radius} - 1rem);
            }
            .kvass-media-render-image__caption {
              border-radius: calc(#{$radius} - 1rem);
            }
          }
        }
      }
    }
  }
}
</style>
