<template>
  <section class="qualities" v-if="value.length">
    <h2 class="demographics-section-title">Kvaliteter</h2>

    <div class="qualities__charts">
      <Doughnut
        v-for="(item, index) in value"
        :key="index"
        :data="[
          Math.floor(parseFloat(item.score.value * 100)),
          Math.floor(100 - parseFloat(item.score.value * 100)),
        ]"
        :title="item.name"
        :subtitle="item.score.text"
      />
    </div>
  </section>
</template>

<script>
import Doughnut from '../graphs/Doughnut.vue'

export default {
  props: {
    value: Array,
  },
  components: {
    Doughnut,
  },
}
</script>

<style lang="scss">
.qualities {
  &__charts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 3rem 2rem;
    @include respond-below('desktop') {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include respond-below('tablet') {
      grid-template-columns: 1fr 1fr;
    }

    @include respond-below('phone') {
      grid-template-columns: 1fr;
    }
  }
}
</style>
