<template>
  <div
    id="app"
    class="route"
    :class="['route--' + view.toLowerCase(), isOnTop ? 'page--is-top' : 'page--is-scrolled']"
  >
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        'nav--' + view.toLowerCase(),
        { 'nav--expanded': isExpanded },
        {
          ['nav--' + view.toLowerCase() + '-expanded']: isExpanded,
          [`nav--${view.toLowerCase()}-${slug}`]: view.toLowerCase() == 'post',
        },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :items="items"
          :title="projectName"
          padding="15px 0"
          :action="mainAction || redirect(['Project', 'Residential'], { scrollTo: 0, id: 'home' })"
          :isOpen.sync="isExpanded"
        >
          <template v-if="isExpanded">
            <HorizontalLine />
            <Navbar :hideButton="true" />
          </template>
        </NavigationComponent>
      </div>
    </div>

    <img
      src="https://templates.kvass.no/custom-pm-1/assets/solgt.png"
      class="elder__navigation-sold"
      v-if="sold"
    />

    <Modal class="navigation-modal" :show="show" @close="onClose" :clickaway="false">
      <Navbar @close="onClose" :logo="logo" :title="projectName" />
    </Modal>

    <transition mode="out-in" name="route">
      <router-view :key="componentKey" />
    </transition>

    <FooterComponent v-if="$route.meta.footer !== false" :navigation="items" :cookie-manager="CM" />
    <AssetComponent />
    <Toolbar v-if="isWebmegler" />
  </div>
</template>

<script>
import { NavigationComponent } from 'vue-elder-navigation'
import { mapState, mapActions } from 'vuex'
import AssetComponent, { EventBus as AssetBus } from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import Toolbar from '@/components/Toolbar'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import { Limit } from '@/filters'
import { DarkTheme } from '@/utils'
import loadCM from '@/gdpr'
import ActionComponent from 'vue-elder-navigation/src/action'
import { ModalComponent as Modal } from 'vue-elder-modal'

import Navbar from '@/components/Navbar'

import HorizontalLine from './components/Custom/HorizontalLine.vue'

import mitt from 'mitt'
const eventBus = mitt()
export { eventBus }

export default {
  mixins: [BrowserApiMixin('app')],
  // watch: {
  //   $route: {
  //     handler(newVal, oldVal) {
  //       if (newVal !== oldVal) this.show = false
  //     },
  //   },
  // },
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      CM: {},
      currentHash: 'home',
      componentKey: 0,
      show: false,
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    slug() {
      return this.$route.params.slug
    },

    isWebmegler() {
      return (
        this.$path('customFields.privatmegleren-project', this.item) ||
        this.$path('customFields.privatmegleren-upcoming', this.item)
      )
    },

    sold() {
      const residential = this.$route.params.id

      if (residential) {
        const r =
          (this.$path('residentials', this.item) || []).find(
            i => i.id === residential.toString(),
          ) || {}

        return r.status === 'sold'
      }

      const getStat = key => {
        const keys = key instanceof Array ? key : [key]
        return keys.reduce((r, c) => r + KvassConfig.get(`stats.${c}`) || 0, 0)
      }

      return getStat('sold') === getStat('total') && getStat('total') !== 0
    },
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },
    logo() {
      return 'https://templates.kvass.no/custom-pm-1/assets/logo.png'

      // if (this.$route.name === 'Project' && !this.isSingular)
      //   return this.isOnTop ? this.logos.dark : this.logos.light
      return this.logos.light
    },
    isDemo() {
      return this.item.demo || false
    },
    posts() {
      return this.item.posts || []
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },
    // backgroundColor() {
    //   return this.$path('customFields.background-color', this.item) || '#FFFFFF'
    // },
    // bodyColor() {
    //   if (DarkTheme(this.backgroundColor)) return '#FFFFFF'
    // },
    projectName() {
      return this.item.name || ''
    },
    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    hasFlatfinder() {
      return this.$path('flatfinders.length', this.item)
    },
    hasStatsTotal() {
      return this.$path('stats.total', this.item)
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    gallery() {
      if (!this.isSingular) return this.$path('media.gallery', this.item) || []
      return this.$path('$store.state.Residential.item.media.gallery') || []
    },
    items() {
      if (this.$route.name !== 'Project')
        return [
          {
            id: 'home',
            label: 'Tilbake til hovedsiden',
            icon: ['far', 'arrow-left'],
            alignment: 'left',
            action: this.redirect('Project', { scrollTo: 0 }),
            order: 1,
          },
          ...this.privatMeglerenItems,
        ]

      return [...this.defaultItems, ...this.customItems, ...this.privatMeglerenItems].sort(
        (a, b) => a.order - b.order,
      )
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },
    mainAction() {
      return 'https://privatmegleren.no'
      if (this.addons.includes('project:flatfinder')) return this.redirectUrl
      if (this.isSingular) return

      return this.redirect(['Project', 'Residential'], { scrollTo: 0, id: 'home' })

      return this.redirect('Project', { scrollTo: 0 })
    },

    privatMeglerenItems() {
      return [
        {
          action: () => {
            this.show = true
          },
          icon: ['fal', 'bars'],
          id: 'menu',
          class: 'elder__navigation-component--menu',
          order: 11,
        },
      ].filter(x => !('condition' in x) || x.condition)
    },

    defaultItems() {
      return [
        {
          class: 'elder__navigation-component--clicked',
          id: 'home',
          label: this.$t('home'),
          action: this.redirect('Project', { scrollTo: 0, id: 'home' }),
          order: 1,
        },

        {
          id: 'section-info',
          label: 'Om prosjektet',
          exact: true,
          action: this.redirect(['Project'], { hash: '#section-info', id: 'section-info' }),
          order: 2,
        },
        {
          id: 'flatfinder',
          label:
            this.$path('customFields.title-overrides.flatfinder', this.item) ||
            KvassConfig.get('customLabels.flatfinder') ||
            this.$tc('flatfinder'),
          exact: true,
          action: this.redirect(['Project'], { hash: '#section-flatfinder', id: 'flatfinder' }),
          condition: this.hasFlatfinder && this.hasStatsTotal,
          order: 3,
        },
        {
          id: 'lead',
          label: this.$t('leadFormTitle'),
          class: 'elder__navigation-component--primary',
          target: '_blank',
          action: this.isWebmegler
            ? this.$path('customFields.lead-url.value', this.item)
            : this.$path('item.customFields.caseId')
            ? `https://privatmegleren.no/registrerinteressent/${this.$path(
                'item.customFields.caseId',
              )}`
            : null,
          order: 10,
        },
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))
        .map((i, index) => ({ ...i, order: i.order ? i.order : (index + 1) * 10 }))
    },
  },
  methods: {
    ...mapActions('Root', ['fetch', 'forceFetchRoot']),

    onClose() {
      this.show = false
    },

    redirect(name, options) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, id } = options

        if (id && this.$route.name !== 'Residential') {
          // this includes more than only nav bar items
          const documents = document.querySelectorAll('[id]')

          documents.forEach(doc => {
            doc.classList.remove('elder__navigation-component--clicked')
          })

          const doc = document.querySelector(`[id=${id}]`)
          doc.classList.add('elder__navigation-component--clicked')
        }

        if (!name.includes(this.$route.name) || params?.slug != this.$route.query?.slug)
          await this.$router.push({ name: name[0], hash, params })
        if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
  },
  mounted() {
    eventBus.on('update', value => {
      this.forceFetchRoot().then(() => (this.componentKey += 1))
    })
  },
  created() {
    const debounce = fn => {
      let frame
      return (...params) => {
        if (frame) cancelAnimationFrame(frame)
        frame = requestAnimationFrame(() => fn(...params))
      }
    }

    const storeScroll = () => {
      this.isOnTop = window.scrollY <= 5
    }

    document.addEventListener('scroll', debounce(storeScroll), { passive: true })
    storeScroll()

    this.fetch() //.then(() => (this.CM = loadCM(this)))
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        {
          rel: 'icon',
          href: 'https://templates.kvass.no/custom-pm-1/assets/favicon.png',
          type: 'image/png',
        },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
    Toolbar,
    ActionComponent,
    Modal,
    Navbar,
    HorizontalLine,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

// #app {
//   background-color: var(--background-color);
//   color: var(--body-color);
// }

.navigation-modal {
  justify-content: end;
  transition: background 0.15s ease-in-out 0s;
  background: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 0.8);
  backdrop-filter: blur(0.3125em);
}

.cookie-manager {
  &__modal {
    color: black;
  }
  &__title {
    font-family: var(--primary-font);
  }
}

.elder__navigation-sold {
  $size: 72px;

  position: fixed;
  top: 90px;
  right: 100px;
  width: $size;
  height: $size;
  z-index: 1002;

  @include respond-below('phone') {
    right: 1rem;
  }
}

.nav {
  transition: background 0.15s ease-in-out 0s;
  position: sticky;
  top: 0;
  width: 100%;
  // height: 90px;
  z-index: get-layer('nav');
  background: var(--secondary);
  box-shadow: 0 10px 15px -10px rgba(black, 0.05);

  // transition: All 150ms ease-in;

  // .elder-dropdown__content-box {
  //   .elder__navigation-component {
  //     color: black !important;
  //   }
  // }

  .elder__navigation {
    &-bars {
      color: white;
    }

    &-component {
      color: white;
      font-weight: 300;
      padding: 10px 15px;
      // text-transform: uppercase;
      // letter-spacing: 0.2em;
      &:hover {
        cursor: pointer;
        color: var(--primary);
      }

      &--clicked {
        font-weight: bold;
        color: var(--primary);
      }

      &--menu {
        font-size: 1.6875rem;
        height: 100%;
        padding-right: 0;
        .elder__navigation-component-icon-right {
          margin-left: 0;
        }
      }
    }

    &-logo {
      flex-grow: 0;
      .elder__navigation-component {
        padding: 5px 0px;
      }
    }

    &-logo-image {
      max-height: 50px !important;
      height: 50px !important;

      @include respond-below('phone') {
        content: url('https://templates.kvass.no/custom-pm-1/assets/logo_small.png');
      }
    }
  }

  &--expanded {
    height: 100vh;
    position: fixed;
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .elder__navigation-node {
      font-size: 1.6875rem;

      .elder__navigation-component {
        white-space: normal;
        word-break: keep-all;
        width: fit-content;
      }
    }

    .horizontal-line {
      width: calc(100% - 20px);
      text-align: center;
      display: flex;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      // padding-inline: 10px;
    }

    ul li {
      padding-inline: 10px;
    }

    background: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 0.8);
    // background: rgba(23, 23, 25, 0.8);
    backdrop-filter: blur(0.3125em);
    .elder__navigation {
      [data-id='menu'] {
        display: none;
      }
    }
  }

  .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: initial;
  }

  .page--is-top & {
    // position: absolute;
    //  background: linear-gradient(to bottom, black -200%, transparent 100%);
  }

  // .route--project.page--is-scrolled & {
  //   position: fixed;
  //   background: var(--primary);
  //   color: $dark;
  //   box-shadow: 0 10px 15px -10px rgba(black, 0.05);
  // }

  // .elder__navigation-component {
  //   &.elder__navigation-component--primary {
  //     background-color: var(--primary) !important;
  //     color: var(--primary-contrast) !important;
  //   }
  // }

  // &--project {
  //   position: absolute;
  //   background: linear-gradient(to bottom, black -200%, transparent 100%);
  //   box-shadow: none;

  //   &-expanded {
  //     box-shadow: none;
  //     background: linear-gradient(180deg, #000 -200%, #00000036);
  //     backdrop-filter: blur(5px);
  //     color: white;
  //     padding-bottom: 2rem;
  //   }
  // }
}
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: scale(1);
}

.route-enter-active,
.route-leave-active {
  transform-origin: top;
  transition: 500ms ease;
}
</style>
