<template>
  <img class="nordea-logo" src="https://templates.kvass.no/custom-pm-1/assets/nordea.svg" />
</template>

<script>
export default {}
</script>

<style lang="scss">
.nordea-logo {
  width: 100%;
  max-width: 200px;
  aspect-ratio: 16/9;
  // from kpb grid
  justify-self: center;
  object-fit: contain;

  @include respond-below('phone') {
    max-width: 125px;
  }
}
</style>
