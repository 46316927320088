<template>
  <div class="doughnut">
    <div class="doughnut__title">{{ title }}</div>

    <div class="doughnut__chart">
      <div class="doughnut__percent">{{ content }}</div>
      <canvas ref="chart"></canvas>
    </div>
    <div v-if="subtitle" class="doughnut__subtitle">{{ subtitle }}</div>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  props: {
    data: Array,
    title: String,
    subtitle: String,
  },
  data() {
    return {}
  },
  computed: {
    content() {
      return this.data[0] + ' %'
    },
  },
  methods: {},
  mounted() {
    const ctx = this.$refs.chart

    const myChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: this.data,
            backgroundColor: ['#E7CFA7', '#393939'],
            borderWidth: 0,
          },
        ],
      },
      options: {
        animation: {
          duration: 2000,
        },
        responsive: true,
        maintainAspectRatio: false,
        events: [],
        cutoutPercentage: 90,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    })
    myChart
  },
  components: {},
}
</script>

<style lang="scss">
.doughnut {
  display: flex;
  flex-direction: column;

  &__title {
    text-align: center;

    &:first-letter {
      text-transform: uppercase;
    }

    @include respond-below('phone') {
      font-size: 0.8rem;
    }
  }
  &__subtitle {
    text-align: center;

    @include respond-above('phone') {
      font-size: 1.2rem;
    }
  }
  &__percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
  }
  &__chart {
    margin: 0.5rem auto;
    position: relative;
    height: 200px;
    width: 200px;
  }
}
</style>
