<template>
  <div class="residentials">
    <div class="residentials__header">
      <h2 class="residentials__title">{{ $attrs.title || title }}</h2>
      <div class="residentials__actions">
        <CheckboxComponent
          v-if="hideTakenLabel"
          v-model="hideTaken"
          :label="hideTakenLabel"
          class="residentials__hide-taken"
        />
        <DropdownComponent
          v-if="sortValues.length"
          :close-on-click="true"
          class="residentials__sort"
        >
          <ButtonComponent class="residentials__sort-trigger">
            <template #label>
              <span class="residentials__sort-label">
                <span>
                  {{ $t('sortOn') }}
                </span>

                <span class="residentials__sort-value">
                  <template v-if="sortLabel">
                    {{ `${sortLabel} (${$t(sort.value >= 1 ? 'lowHigh' : 'highLow')})` }}
                  </template>

                  <template v-else>
                    {{ $t('select') }}
                  </template>
                </span>
              </span>
            </template>
          </ButtonComponent>

          <template #dropdown>
            <template v-for="item in sortValues">
              <ButtonComponent
                v-for="(value, index) in 2"
                :key="index + item.key"
                @click="updateSort(item, index === 1 ? 1 : -1)"
              >
                <template #label>
                  <span class="residentials__sort-item-label">
                    <span>
                      {{ item.label }}
                    </span>
                    <span> ({{ $t(index === 1 ? 'lowHigh' : 'highLow') }}) </span>
                  </span>
                </template>
              </ButtonComponent>
            </template>
          </template>
        </DropdownComponent>
      </div>
    </div>
    <div class="residentials__scroller">
      <table
        class="residentials__list table table--default table--padding"
        :class="disableNavigation ? 'residentials__list--navigation-disabled' : 'table--hover'"
      >
        <thead>
          <tr>
            <th class="text--center text--nowrap" data-id="th_unit">
              {{ $tc('unit', 1) }}
            </th>
            <th v-if="showStatus" data-id="th_status" class="text--center">
              {{ $tc('status', 1) }}
            </th>

            <th
              :data-id="'th_' + property"
              class="text--center"
              v-for="(property, index) in filteredProperties"
              :key="'value_th_' + index"
            >
              {{ getPropertyLabel(property) }}
            </th>
            <th :data-id="'th_price'" class="text--center">{{ $t('price') }}</th>
            <th :data-id="'th_jointDept'" class="text--center">Fellesgjeld</th>
            <th :data-id="'th_totalPrice'" class="text--center">Totalpris</th>
            <th :data-id="'th_buy'" class="text--center">Status</th>

            <th v-if="hasRowSlot"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            :data-id="'tr_' + index"
            v-for="(e, index) in items"
            :key="'tr_' + index"
            @click="!disableNavigation && onClick(e)"
          >
            <template v-if="e">
              <td class="text--center text--nowrap" data-id="td_unit">
                {{ e.number }}
              </td>
              <td v-if="showStatus" class="text--center" data-id="td_status">
                {{
                  statusLabels[`${e.sale.type}.${e.status}`] ||
                    $t(`residentialStatus:${e.sale.type}.${e.status}`)
                }}
              </td>
              <td
                :data-id="'td_' + property"
                class="text--center"
                v-for="(property, index) in filteredProperties"
                :key="'value__td' + index"
              >
                {{ getProperty(e, property) }}
              </td>
              <td data-id="td_price" class="text--center text--nowrap">
                {{ getPrice(e) }}
              </td>
              <td data-id="td_jointDept" class="text--center text--nowrap">
                {{ getjointDebt(e) }}
              </td>
              <td data-id="td_totalPrice" class="text--center text--nowrap">
                {{ getTotalPrice(e) }}
              </td>
              <td data-id="td_price" class="text--center text--nowrap residentials-actions">
                <template v-if="e.status === 'upcoming'">
                  <span class="residentials__item--upcoming"></span>
                </template>

                <template v-else-if="e.status === 'sold'">
                  <span class="residentials__item--sold">Solgt</span>
                </template>

                <a v-else class="residentials__item--sale" :href="getBuyLink(e)">Kjøp</a>
              </td>
              <td v-if="hasRowSlot">
                <slot name="row-end" :item="e" />
              </td>
            </template>
          </tr>
          <template v-if="items.length">
            <tr v-for="n in Math.max(0, 3 - items.length)" :key="'placeholder_' + n">
              <td :colspan="5 + filteredProperties.length">&nbsp;</td>
              <td v-if="hasRowSlot"></td>
            </tr>
          </template>
        </tbody>
      </table>
      <LazyLoad>
        <InfiniteLoading @infinite="next" :identifier="infiniteId" spinner="spiral">
          <template #no-more>
            {{ $t(items.length ? 'noMoreUnits' : 'noUnits') }}
          </template>
          <template #no-results>
            {{ $t(items.length ? 'noMoreUnits' : 'noUnits') }}
          </template>
        </InfiniteLoading>
      </LazyLoad>
      <ScrollIndicator :value="['bottom']" />
    </div>
  </div>
</template>

<script>
import BrowserApiMixin from './mixins/browser-api'
import { Currency } from './filters'
import { path } from 'ramda'
import { Clone } from './utils'
import { getResidentials } from './api'

import LazyLoad from 'vue-make-lazy'
import InfiniteLoading from 'vue-infinite-loading'

import { CheckboxComponent } from 'vue-elder-checkbox'
import { DropdownComponent } from 'vue-elder-dropdown'
import ScrollIndicator from '@kvass/scroll-indicator'
import mitt from 'mitt'

const eventBus = mitt()

const template = {
  item: {
    docs: [],
    page: null,
    pages: null,
  },
  paginate: {
    page: 1,
    limit: 50,
  },
  promises: {
    fetch: null,
  },
}

export { eventBus }
export default {
  mixins: [BrowserApiMixin('residentials')],
  props: {
    project: {
      type: String,
      required: true,
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
    properties: {
      type: Array,
      default: () => [],
    },
    disableNavigation: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    statusLabels: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    filter: {
      handler() {
        this.reset()
        this.infiniteId += 1
      },
      deep: true,
      immediate: true,
    },
    hideTaken() {
      this.reset()
      this.infiniteId += 1
    },
  },
  data() {
    return {
      ...Clone(template),
      hideTaken: !KvassConfig.get('siteSettings.showSoldResidentials'),
      infiniteId: +new Date(),
      title: KvassConfig.get('customLabels.residentialList') || this.$tc('unit', 2),
      sort: {},
    }
  },
  computed: {
    filteredProperties() {
      return this.properties.filter(prop => this.getPropertyLabel(prop))
    },

    sortValues() {
      return [
        { key: 'price', label: this.$t('price') },
        { key: 'number', label: this.$t('number') },
        ...this.filteredProperties.map(key => {
          return {
            label: this.getPropertyLabel(key),
            key: 'properties.' + key,
          }
        }),
      ]
    },
    sortLabel() {
      if (this.sort.label) return this.sort.label
      return this.$t(this.sort.key) || this.getPropertyLabel(this.sort.key)
    },
    hideTakenLabel() {
      let sold = KvassConfig.get('stats.sold'),
        rented = KvassConfig.get('stats.rented')
      if (!sold && !rented) return

      return `${this.$t('hide')} ${[
        sold > 0 ? this.$tc('residentialStatus:sale.sold').toLowerCase() : null,
        rented > 0 ? this.$tc('residentialStatus:rent.rented').toLowerCase() : null,
      ]
        .filter(Boolean)
        .join(' / ')}`
    },
    isLast() {
      return this.item.page >= this.item.pages
    },
    items() {
      return this.item.docs || []
    },
    hasRowSlot() {
      let rowEnd = this.$scopedSlots['row-end']
      if (!rowEnd) return
      return rowEnd()
    },
  },
  methods: {
    onClick(e) {
      eventBus.emit('click', e)
      if (e.redirectUrl) return (window.location.href = e.redirectUrl)
      this.$router.push({
        name: 'Residential',
        params: { id: e.id },
        query: this.$route.query,
      })
    },
    reset() {
      this.item = Clone(template.item)
      this.paginate = Clone(template.paginate)
    },
    fetch() {
      if (this.item.pages === 0 || (this.item.pages && this.item.page === this.item.pages)) return

      return (this.promises.fetch = getResidentials({
        paginate: this.paginate,
        filter: {
          project: this.project,
          status: this.hideTaken ? ['!sold', '!draft', '!rented'] : ['!draft'],
          ...this.filter,
        },
        properties: this.properties,
        sort: { key: this.sort.key, value: this.sort.value },
      }).then(data => {
        let { docs, ...rest } = data

        if (docs) {
          if (data.page > this.item.page) this.item.docs.push(...docs)
          if (data.page < this.item.page) this.item.docs.unshift(...docs)
        }

        Object.entries(rest).forEach(([key, value]) => {
          this.item[key] = value
        })

        return data
      }))
    },
    next($state) {
      this.fetch().then(() => {
        this.paginate.page++
        if (this.isLast) $state.complete()
        else $state.loaded()
      })
    },
    getProperty(item, key) {
      let match = item.properties.find(p => p.key === key)
      if (!match) return

      return match.formatted
    },
    getPropertyLabel(key) {
      let match = this.items.filter(Boolean).find(i => i.properties.find(p => p.key === key))
      if (!match) return

      let property = match.properties.find(p => p.key === key)

      return property.label || this.$t(property.key)
    },
    getjointDebt(item) {
      const value = path(['sale', 'jointDebt'], item)

      if (!value || item.status === 'sold') return '-'

      const price = Currency(value) || '-'

      switch (item.status) {
        case 'sale':
          return price
        case 'reserved':
          return `${
            price && !KvassConfig.get('siteSettings.hideReservedPrice') ? ` ${price}` : '-'
          }`
        // case 'rent':
        //   if ([null, undefined].includes(price)) return this.$t('priceOnRequest')
        //   return `${price} / ${this.$t('multiplicatorMonthly')}.`
        default:
          return '-'
      }
    },

    getBuyLink(e) {
      return path(['customFields', 'buy-url', 'value'], e) || path(['sale', 'bidUrl'], e)
    },

    getTotalPrice(item) {
      if (item.status === 'sold') return '-'

      const totalCost = Math.floor(
        (path(['customFields', 'total-cost'], item) || []).reduce((acc, cost) => {
          return acc + cost.value
        }, 0),
      )

      const value = path(['sale', 'value'], item) || 0
      const jointDept = path(['sale', 'jointDebt'], item) || 0

      const price = Currency(value + jointDept + totalCost)

      switch (item.status) {
        case 'sale':
          if ([null, undefined, 0].includes(price)) return this.$t('priceOnRequest')
          return price
        case 'reserved':
          return `${this.$t('residentialStatus:' + item.sale.type + '.' + item.status)}${
            price && !KvassConfig.get('siteSettings.hideReservedPrice') ? ` (${price})` : ''
          }`
        case 'rent':
          if ([null, undefined].includes(price)) return this.$t('priceOnRequest')
          return `${price} / ${this.$t('multiplicatorMonthly')}.`

        default:
          return this.$t('residentialStatus:' + item.sale.type + '.' + item.status)
      }
    },

    getPrice(item) {
      if (item.status === 'sold') return '-'
      let price = Currency(path(['sale', 'value'], item))
      switch (item.status) {
        case 'sale':
          if ([null, undefined].includes(price)) return this.$t('priceOnRequest')
          return price
        case 'reserved':
          return `${this.$t('residentialStatus:' + item.sale.type + '.' + item.status)}${
            price && !KvassConfig.get('siteSettings.hideReservedPrice') ? ` (${price})` : ''
          }`
        case 'rent':
          if ([null, undefined].includes(price)) return this.$t('priceOnRequest')
          return `${price} / ${this.$t('multiplicatorMonthly')}.`
        default:
          return this.$t('residentialStatus:' + item.sale.type + '.' + item.status)
      }
    },
    updateSort(item, value) {
      this.sort = { value, ...item }
      this.reset()
      this.infiniteId += 1
    },
  },
  components: {
    InfiniteLoading,
    CheckboxComponent,
    ScrollIndicator,
    DropdownComponent,
    LazyLoad,
  },
}
</script>

<style lang="scss">
@import './styles.scss';

.residentials {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-corner {
    background: var(--secondary);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 60px;
    background: rgba(255, 255, 255, 0.166);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #949191;
    border-radius: 60px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(162, 162, 162, 0.415);
  }

  $residentials-responsive: 767px;
  position: relative;

  &__scroller {
    max-height: 500px;

    overflow-y: auto;

    @include scrollShadow();

    th {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;
    }
  }

  tbody tr {
    cursor: pointer;

    td {
      transition: background-color 150ms ease-out;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1rem;
    margin-bottom: 1rem;

    h2 {
      margin: 0 !important;
    }

    @media (max-width: $residentials-responsive) {
      flex-direction: column;
      align-items: flex-start;
      span {
        font-size: 0.7rem;
      }
    }
  }

  &__title {
    flex-shrink: 0;
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    justify-content: flex-end;
  }

  &__sort {
    justify-content: center;
    align-items: center;

    &-trigger {
      .elder-button__label {
        padding: 0.5em 1em;
        background-color: css-alpha('primary', 0.1) !important;
      }
    }
    &-label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        &:first-child {
          opacity: 0.7;
          font-size: 0.7em;
        }
      }
    }

    &-item-label {
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
    }

    .elder-dropdown__content-box {
      max-height: 300px;
      overflow-y: auto;

      .elder-button {
        border: none !important;
        display: flex;
        justify-content: initial;
      }
    }
    @media (max-width: $residentials-responsive) {
      margin-left: auto;
    }
  }

  &__list {
    width: 100%;

    @media (max-width: $residentials-responsive) {
      font-size: 0.7em;
    }

    &--navigation-disabled {
      tr {
        cursor: default !important;
      }
    }
  }

  &__item {
    &--rented,
    &--sold {
      color: var(--error);
      font-weight: bold;
    }

    &--reserved {
      color: var(--warning);
      font-weight: bold;
    }
    &--upcoming {
      color: rgba(#272727, 0.4);
      font-style: italic;
    }
    &--sale {
      color: var(--primary);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &--rent {
      font-weight: bold;
    }
  }
  .scroll-indicator__arrow--bottom {
    bottom: -18px;
  }
}
</style>
