<template>
  <div
    class="read-more"
    :class="{ 'read-more__cover': !expanded }"
    :style="{ height: !expanded ? height : '100%' }"
  >
    <slot />

    <button v-if="!expanded" type="button" class="read-more__expand" @click="expanded = !expanded">
      <FontAwesomeIcon :icon="['fas', 'plus']" size="1x" />
      <span class="read-more__expand-text">Vil du vite mer?</span>
    </button>

    <div v-if="!expanded" class="read-more__overlay"></div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '500px',
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
}
</script>

<style lang="scss">
.read-more {
  position: relative;
  overflow: hidden;
  &__cover {
  }

  &__overlay {
    position: absolute;
    left: 0px;
    bottom: -0.2em;
    width: 100%;
    min-height: 20em;
    pointer-events: none;

    background: linear-gradient(to top, var(--secondary, #000), rgba(23, 23, 25, 0));
  }

  &__expand {
    cursor: pointer;
    justify-content: center;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;

    background: transparent;

    color: white;

    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, 0%);
    margin: 0 auto;

    z-index: 2;

    svg {
      color: var(--primary);
    }

    &-text {
      font-weight: bold;
      font-size: 0.8rem;
    }
  }
}
</style>
