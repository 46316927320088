<template>
  <div class="accordion" v-if="content.length">
    <div class="accordion-label">
      <h2>{{ title }}</h2>
      <button class="accordion-trigger" @click="trigger">
        {{ openText }} <FontAwesomeIcon :icon="openIcon" />
      </button>
    </div>

    <ul class="accordion-wrapper">
      <ChildrenComponent
        v-for="(child, index) in content"
        :key="index"
        :content="child.content"
        :title="child.title"
        :fields="child.fields || []"
        :level="0"
        :openAll="allOpen"
      >
      </ChildrenComponent>
    </ul>
  </div>
</template>

<script>
import ChildrenComponent from './ChildrenComponent.vue'

export default {
  props: {
    data: {
      accordion: {
        type: Object,
        default: null,
      },
    },
  },

  data() {
    return {
      allOpen: false,
    }
  },

  methods: {
    trigger() {
      this.allOpen = !this.allOpen
    },
  },

  computed: {
    title() {
      return this.$path('accordion.title', this.data) || 'Les mer om prosjektet'
    },
    content() {
      if (!this.data.accordion) return []

      if (this.$path('accordion.fields', this.data))
        return this.$path('accordion.fields', this.data)

      return Object.entries(this.data.accordion.value).map(([key, value]) => ({
        title: (key || '').replace(/\s*\(.*?\)/g, ''),
        content: Array.isArray(value) ? '' : value,
        fields: Array.isArray(value)
          ? value.map(v => {
              return {
                title: (v.label || '').replace(/\s*\(.*?\)/g, ''),
                content: v.value,
                fields: [],
              }
            })
          : [],
      }))
    },

    openText() {
      return this.allOpen ? 'Lukk alle seksjoner' : 'Åpne alle seksjoner'
    },

    openIcon() {
      return this.allOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']
    },
  },
  components: {
    ChildrenComponent,
  },
}
</script>

<style lang="scss">
.accordion {
  // position: relative;
  &-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;

    h2 {
      margin-bottom: 1.5rem;
    }

    svg {
      margin-left: 1rem;
      padding: 5px;
      background-color: white;
      color: var(--secondary);
      border-radius: 100%;
    }
  }

  &-trigger {
    color: inherit;
    font-weight: bold;
    display: flex;
    text-transform: uppercase;

    @include respond-below('phone') {
      display: none;
    }
  }

  &-wrapper {
    list-style-type: none;
    padding-inline-start: 0;
  }
}
</style>
