function createMapPin(size) {
  const el = document.createElement('div')
  // const size = 73
  el.className = 'marker'
  // el.style.backgroundImage = `url(https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/pin.svg)`
  el.style.backgroundImage = `url(https://templates.kvass.no/custom-pm-1/assets/kvadrat.png)`

  // el.style.border = '3px solid var(--primary)'
  el.style.width = `${size}px`
  el.style.height = `${size}px`
  el.style.backgroundSize = '100%'

  return el
}

function numberWithSpaces(x) {
  if (!x) return
  return `<span style="white-space:nowrap">${x
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>`
}

export default function() {
  const get = path => this.$path(path, this.$store.state)

  const isWebmegler = Boolean(
    get('Project.item.customFields.privatmegleren-project') ||
      get('Project.item.customFields.privatmegleren-upcoming'),
  )

  const estateId =
    get('Project.item.customFields.privatmegleren-project') ||
    get('Project.item.customFields.privatmegleren-upcoming') ||
    get('Project.item.customFields.caseId')

  const getStat = key => {
    const keys = key instanceof Array ? key : [key]
    return keys.reduce((r, c) => r + KvassConfig.get(`stats.${c}`) || 0, 0)
  }

  const sold = getStat('sold')
  const sale = getStat('sale')
  const total = getStat('total')
  const upcoming = getStat('upcoming')

  const slug = window.location.host

  const isUpcoming =
    get('Project.item.customFields.privatmegleren-upcoming') || slug.includes('-kommer-for-salg')

  const showStats = () => {
    return getStat('total') !== 0 || isUpcoming
  }

  const isSold = () => {
    return sold === total && total !== 0
  }

  const statsContent = () => {
    if (isUpcoming || (upcoming !== 0 && sale === 0)) return 'Kommer for salg'
    if (isSold()) return 'Alle enheter er solgt'

    return `${sale} av ${total} ledige enheter`
  }

  const galleryData = [
    ...(get('Project.item.media.cover') || []),
    ...(get('Project.item.media.gallery') || []),
  ].filter((value, index, self) => index === self.findIndex(t => t.url === value.url))

  const propertyType = () => {
    const type = get('Project.item.metadata.residentialProperties.propertyType') || []

    if (!type || !type.length)
      return get(`Project.item.customFields.residentialProperties.propertyType.value`)

    return type.map(type => this.$tc(`propertyType:${type}`)).join(', ')
  }

  const priceFrom = () => {
    const values = (get('Project.item.residentials') || [])
      .filter(r => !['upcoming', 'sold', 'draft'].includes(r?.status))
      .map(r => r?.sale?.value)
      .filter(Boolean)
      .sort((a, b) => a - b)

    if (!values.length) {
      let value = get(`Project.item.customFields.price`)
      if (!value) return
      return `kr ${numberWithSpaces(value)}`
    }

    return 'kr ' + numberWithSpaces(values[0])
  }

  const jointdebt = () => {
    const values = (get('Project.item.residentials') || [])
      .filter(r => !['upcoming', 'sold', 'draft'].includes(r?.status))
      .map(r => r?.sale?.jointDebt)
      .filter(Boolean)
      .sort((a, b) => a - b)
    if (!values.length) {
      let value = get(`Project.item.customFields.residentialProperties.jointDebt.value`)
      if (!value) return
      return `kr ${numberWithSpaces(value)}`
    }

    return 'kr ' + numberWithSpaces(values[0])
  }

  const ownershipType = () => {
    const type = get('Project.item.metadata.residentialProperties.ownershipType') || []

    if (!type || !type.length)
      return get(`Project.item.customFields.residentialProperties.ownershipType.value`)

    return type.map(type => this.$tc(`ownershipType:${type}`)).join(', ')
  }

  const getMetaDataValue = (key, suffix = '') => {
    const type = get(`Project.item.metadata.residentialProperties.${key}`) || []

    if (!type || !type.length) {
      let value = get(`Project.item.customFields.residentialProperties.${key}.value`)
      if (!value) return

      let upperValue = get(`Project.item.customFields.residentialProperties.${key}To.value`)
      if (upperValue) return `${value} - ${upperValue} ${suffix}`

      return `${value} ${suffix}`
    }

    if (!type[0]) return

    return type.length < 2
      ? `${type[0]} ${suffix}`
      : `${type[0]} - ${type[type.length - 1]} ${suffix}`
  }

  const braI = getMetaDataValue('useableInternalArea', 'm²')

  let keyStats = [
    {
      value: braI ? braI : getMetaDataValue('primaryRoomArea', 'm²'),
      label: braI ? 'BRA-I' : 'Primærrom',
    },
    { value: getMetaDataValue('useableArea', 'm²'), label: 'Bruksareal' },
    { value: priceFrom(), label: 'Pris fra' },
    { value: ownershipType(), label: 'Eierform' },
    { value: propertyType(), label: 'Boligtype' },
    { value: jointdebt(), label: 'Fellesgjeld fra' },
    { value: getMetaDataValue('numberOfRooms'), label: 'Antall rom' },
    { value: getMetaDataValue('numberOfBedrooms'), label: 'Soverom' },
  ].filter(item => item.value)

  const leadUrl = isWebmegler
    ? get('Project.item.customFields.lead-url.value')
    : `https://privatmegleren.no/registrerinteressent/${get('Project.item.customFields.caseId')}`

  const openhouseUrl = isWebmegler
    ? get('Project.item.customFields.openhouse-url.value')
    : `https://privatmegleren.no/${get('Project.item.customFields.caseId')}/visningspaamelding`

  const salesAssignment = isWebmegler
    ? get('Project.item.customFields.sales-assignment.value')
    : get('Project.item.customFields.sales-assignment')

  const description =
    (isWebmegler && get('Project.item.description')
      ? `<p>${get('Project.item.description')}</p>`
      : get('Project.item.customFields.additional-sections.0.content')) || ''

  return [
    {
      component: 'Section',
      condition: galleryData.length,
      props: {
        width: 'full',
        id: 'hero',
        class: 'section-hero',
        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            props: {
              aspectRatio: '16/9',
              class: 'hero hero__slider',

              autoPlay: false,
              photoSwipe: false,
              navIcon: {
                left: ['fas', 'chevron-left'],
                right: ['fas', 'chevron-right'],
              },
            },
            data: galleryData,
            blocks: {
              bottom: showStats()
                ? {
                    component: 'StatsButton',
                    props: {
                      label: statsContent(),
                      class: 'hero__stats',
                      hash: get('Project.item.flatfinders.length')
                        ? '#section-flatfinder'
                        : '#section-residentials',
                    },
                  }
                : {},
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'small',
        id: '',
        class: 'header',
        style: '',
      },
      blocks: {
        default: [
          // {
          //   component: 'Text',
          //   props: {
          //     textAlign: 'center',
          //     class: 'header__stats',
          //   },
          //   data: {
          //     content: getSaleLabel(),
          //   },
          // },

          {
            component: 'Text',
            props: {
              textAlign: 'center',
              class: 'header__title',
            },
            data: {
              content: `<h1>${get('Project.item.customFields.project-hero.title') ||
                (get('Project.item.name') || '')
                  .replace('-kommer-for-salg', '')
                  .replace('-demo', '')
                  .replace(new RegExp('hovedoppdrag' + '$'), '')
                  .replace(new RegExp('Hovedoppdrag' + '$'), '')
                  .replace(new RegExp('-' + '$'), '')
                  .trim()}</h1>`,
            },
          },

          {
            component: 'Text',
            condition: isWebmegler
              ? get('Project.item.customFields.preamble')
              : get('Project.item.description'),
            props: {
              textAlign: 'center',
              class: 'header__content',
            },
            data: {
              content: isWebmegler
                ? `<p>${get('Project.item.customFields.preamble')}</p>`
                : get('Project.item.description'),
            },
          },

          {
            component: 'ShareButton',
            condition: salesAssignment || leadUrl,
            props: {
              style: 'padding-top: 2rem',
            },
            blocks: {
              default: [
                {
                  component: 'Button',
                  condition: salesAssignment,
                  data: {
                    label: 'Komplett digital salgsoppgave',
                    href: salesAssignment,
                    target: '_blank',
                    theme: 'primary',
                    tag: 'a',
                    icon: ['fas', 'external-link'],
                  },
                },
                {
                  component: 'Button',
                  condition: !salesAssignment,
                  data: {
                    label: 'Meld interesse',
                    href: leadUrl,
                    target: '_blank',
                    theme: 'primary',
                    tag: 'a',
                    icon: ['fas', 'external-link'],
                  },
                },
              ],
            },

            data: {
              label: 'Del',
              theme: 'secondary',
              tag: 'button',
              icon: ['far', 'share-square'],
            },
          },
        ],
      },
    },

    // Nøkkelinfo seksjon
    {
      component: 'Section',
      condition: keyStats.length,
      props: {
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'KeyStats',

            data: {
              content: keyStats.splice(0, 5),
            },
            props: {
              columns: 5,
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'info',
        class: 'key-info-section',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'key-info-section-header',
            },
            data: {
              content: `<h2>Om prosjektet</h2>`,
            },
            condition: isWebmegler,
          },
          {
            component: 'ReadMore',
            condition: () => description.length > 1500,
            props: {},
            blocks: {
              default: {
                component: 'Text',
                props: {
                  class: 'section-info__description',
                },
                data: {
                  content: description,
                },
              },
            },
          },
          {
            component: 'Text',
            condition: () => !(description.length > 1500) && description,
            props: {
              class: 'section-info__description',
            },
            data: {
              content: description,
            },
          },

          {
            component: 'HorizontalLine',
          },
          {
            component: 'Grid',
            props: {
              columns: 1,
              gap: '0rem',
              class: 'key-info-section-layout',
            },
            blocks: {
              default: [
                {
                  component: 'KeyInfo',
                  data: {
                    residentialProperties:
                      get('Project.item.customFields.residentialProperties') || {},
                    properties: get('Project.item.customFields.properties'),
                    residentials: get('Project.item.residentials') || [],
                    metadataProperties: get('Project.item.metadata.residentialProperties'),
                  },
                },

                {
                  component: 'VerticalLine',
                  props: {
                    class: '',
                  },
                },

                // for mobile
                {
                  component: 'HorizontalLine',
                  props: {
                    class: 'key-info-section-line-tablet',
                  },
                },

                {
                  component: 'TotalCost',
                  data: {
                    residentials: get('Project.item.residentials') || [],
                    totalCost: get('Project.item.customFields.total-cost'),
                    price: get('Project.item.customFields.price'),
                    priceTo: get('Project.item.customFields.priceTo.value'),
                    jointDebt: get(
                      'Project.item.customFields.residentialProperties.jointDebt.value',
                    ),
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Root.item.flatfinders.0.id'),
      props: {
        width: 'medium',
        id: 'flatfinder',
        class: 'section-flatfinder kpb-section--width-full-mobile',
        style: 'padding-bottom:1rem',
      },
      blocks: {
        default: [
          {
            component: 'FlatfinderCustom',
            condition: get('Root.item.flatfinders.0.id'),
            props: {
              properties: get('Project.item.metadata.siteSettings.featuredResidentialProperties'),
              value: get('Project.item.flatfinders'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.id') && get('Root.item.stats.total'),
      props: {
        width: 'medium',
        id: 'residentials',
        class: 'section-residentials',
        style: 'padding-top:1rem',
      },
      blocks: {
        default: [
          // {
          //   component: 'ScrollAnchor',
          //   data: 'residentials',
          // },
          {
            component: 'ResidentialsList',
            props: {
              project: get('Project.item.id'),
              title: get('Project.item.customFields.title-overrides.residentials'),
              properties: [
                'floor',
                'numberOfBedrooms',
                'useableArea',
                braI ? 'useableInternalArea' : 'primaryRoomArea',
                'plotArea',
                'propertyType',
                'numberOfParkingSpots',
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.address.location.coordinates'),
      props: {
        width: 'full',
        id: 'map',
        class: 'section-map',
        style: 'padding-bottom:0;',
      },
      blocks: {
        default: [
          // {
          //   component: 'ScrollAnchor',
          //   data: 'map',
          // },
          {
            component: 'Map',
            props: {
              //   aspectRatio: '16/9',
              aspectRatio: '21/9',
              showControls: true,
              displayAddress: false,
              mapOptions: {
                zoom: 14,
                interactive: true,
                dragPan: true,
              },
              markerOptions: {
                element: createMapPin(73),
              },
            },
            data: {
              //...get('Project.item.address'),
              coordinates: get('Project.item.address.location.coordinates'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        style: 'padding-block: 0',
      },
      blocks: {
        default: [
          {
            component: 'Demographics',
            props: {},
            data: {
              estateId: estateId,
              system: isWebmegler ? 'webmegler' : 'core',
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.customFields.accordion'),
      props: {
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'Accordion',
            data: {
              accordion: get('Project.item.customFields.accordion'),
            },
          },
        ],
      },
    },

    {
      component: 'Banner',
      condition: salesAssignment,
      data: {
        // override with CSS
        backgroundImage: '',
      },
      props: {
        backgroundColor: 'var(--secondary)',
        class: 'documents__background',
      },
      blocks: {
        default: [
          {
            component: 'Section',
            props: {
              width: 'medium',
              class: 'documents-section',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    class: 'documents__content',
                  },
                  data: {
                    content: '<h2>Dokumenter</h2>',
                  },
                },
                {
                  component: 'Button',
                  data: {
                    label: 'Komplett digital salgsoppgave',
                    href: salesAssignment,
                    target: '_blank',
                    theme: 'primary',
                    tag: 'a',
                    icon: ['fas', 'external-link'],
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        class: 'nordea-section',
      },
      blocks: {
        default: [
          {
            component: 'Grid',
            props: {
              columns: 2,
              class: 'nordea-section-layout',
            },
            blocks: {
              default: [
                {
                  component: 'Flex',
                  props: {
                    flexDirection: 'column',
                    class: 'nordea-section__content-wrapper',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Text',
                        props: {
                          class: 'nordea-section__content',
                        },
                        data: {
                          content:
                            '<h2>Trenger du finansiering?</h2><p>Ring oss på 232 06001, avtal et videomøte eller chat med oss på nordea.no - alle dager, hele døgnet. Du kan også søke om et finansieringsbevis direkte på <a target="_blank" href="https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/jeg-onsker-a-kjope-en-bolig.html">nordea.no/finansieringsbevis</a>.</p>',
                        },
                      },
                      {
                        component: 'Flex',
                        props: {},
                        blocks: {
                          default: [
                            {
                              component: 'Button',
                              data: {
                                label: 'Søk finansiering',
                                href:
                                  'https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/privatmegleren-og-nordea-finansiering.html?cid=partner-h8uh2yl6vbiz1gsb5973',

                                target: '_blank',
                                theme: 'secondary',
                                tag: 'a',
                                icon: ['fas', 'external-link'],
                              },
                            },
                            {
                              component: 'Button',
                              data: {
                                label: 'Lånekalkulator',
                                href:
                                  'https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/boliglanskalkulator.html?cid=partner-75jkzb7zhkc7indjt2yg#/',

                                target: '_blank',
                                theme: 'secondary',
                                tag: 'a',
                                icon: ['fas', 'external-link'],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },

                {
                  component: 'Nordea',
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: galleryData.length,
      props: {
        width: galleryData.length > 1 ? 'large' : 'medium',
        style: `padding-block: 1rem; ${
          galleryData.length > 1 ? '--section-padding-inline: 0;' : ''
        }`,
        class: 'gallery-section kpb-section--width-full-mobile',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            props: {
              autoPlay: false,
              photoSwipe: true,
              navigationPlacement: galleryData.length > 1 ? 'outside' : 'center',
              class: 'slider__gallery',
              navIcon: {
                left: ['fas', 'chevron-left'],
                right: ['fas', 'chevron-right'],
              },
            },
            data: galleryData,
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        class: 'roles-section',
      },
      blocks: {
        default: [
          {
            component: 'Grid',
            props: {
              class: 'roles-layout',
              columns: 2,
            },
            blocks: {
              default: [
                {
                  component: 'Openhouse',
                  data: {
                    content: get('Project.item.openhouses'),
                    leadUrl: leadUrl,
                    openhouseUrl: openhouseUrl,
                  },
                },

                {
                  condition: get('Project.item.roles.salesman'),
                  component: 'Roles',
                  data: {
                    content: get('Project.item.roles.salesman'),
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Banner',
      data: {
        // override with CSS
        backgroundImage: '',
      },
      props: {
        backgroundColor: 'var(--secondary)',
        class: 'sale__background',
      },
      blocks: {
        default: [
          {
            component: 'Section',
            props: {
              width: 'medium',
              class: 'sale__section',
            },

            blocks: {
              default: [
                {
                  component: 'Grid',
                  props: {
                    class: 'sale__section-layout',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Text',
                        props: {
                          class: 'sale__content',
                        },
                        data: {
                          content:
                            '<h2>Salg av eiendom gjennnom PrivatMegleren</h2><p>Skal du også selge egen eiendom?<br>Les mer om salg gjennom PrivatMegleren</p><a target="_blank" data-cta="primary" href="https://privatmegleren.no/leveransebeskrivelse-selge-bolig">Les mer</a>',
                        },
                      },
                      {
                        component: 'PM',
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
