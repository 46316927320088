<template>
  <div v-if="items.length" class="roles">
    <h2 class="roles__title">{{ $t('getInTouch') }}</h2>
    <h4 class="roles__subtitle">Har du spørsmål? Gi oss beskjed, så hjelper vi deg gjerne.</h4>

    <div class="roles__list">
      <div class="roles__item" v-for="(item, index) in items" :key="index">
        <div
          class="roles__avatar"
          :style="{ backgroundImage: `url(${getAvatar(item.avatar, item.name)})` }"
        ></div>

        <div class="roles__contact">
          <a class="roles__name" :href="meglerUrl(item.name)">
            {{ item.name }}
            <FontAwesomeIcon :icon="['fas', 'external-link']" size="1x" class="roles__name-icon" />
          </a>
          <div class="roles__user-title">
            {{ item.title }}
          </div>

          <span class="roles__email">
            <a v-if="item.email" :href="`mailto:${item.email}`">{{ item.email }}</a>
          </span>

          <div class="roles__contact-bottom">
            <a v-if="item.phone" class="roles__phone" :href="`tel:${removeWhitespace(item.phone)}`">
              <FontAwesomeIcon :icon="['far', 'phone']" size="1x" class="roles__icon" />
              {{ phoneFormater(item.phone) }}
            </a>
            <a class="roles__vcard" :href="meglerUrl(item.name, '/vcard')" target="_blank">
              <FontAwesomeIcon :icon="['far', 'address-card']" size="1x" class="roles__icon" />
              Visittkort
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { brokers } from '@/brokers'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    meglerUrl(megler, path = '') {
      const name = megler
        .toLowerCase()
        .replaceAll(' ', '_')
        .replaceAll('é', 'e')

      return `https://privatmegleren.no/megler/${name}${path}`
    },

    getAvatar(url, name) {
      if (url && !(url || '').includes('\/api\/avatar\/')) return url

      const broker = brokers.find(f => f.name === name) || {}

      return broker.image || 'https://templates.kvass.no/custom-pm-1/assets/roles.png'
    },

    removeWhitespace(x) {
      if (!x) return

      return x.toString().replaceAll(' ', '')
    },

    phoneFormater(x) {
      if (!x) return

      return this.removeWhitespace(x).replace(/\B(?=(\d{2})+(?!\d))/g, ' ')
    },
  },
  computed: {
    items() {
      return this.$path('data.content') || []
    },
  },
}
</script>

<style lang="scss">
.roles {
  &__avatar {
    // $size: clamp(90px, 180px, 200px);
    height: 200px;
    width: 160px;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
  }
  &__list {
    margin-top: 2.5rem;

    display: flex;

    flex-direction: column;
    gap: 3rem;
  }

  &__name {
    // margin-bottom: 0.3rem;
    color: var(--primary);
    @include header-font(2rem);
    text-decoration: none;
    svg {
      color: white;
      margin-left: 5px;
      font-size: 1.5rem;
    }
  }

  &__title {
  }

  &__contact {
    display: flex;
    flex-direction: column;
  }

  &__subtitle {
    margin: 0;
    // margin-bottom: 2rem;
  }

  &__user-title {
  }

  &__contact-bottom {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 2rem;
    padding-block: 1rem;

    @include respond-below('phone') {
      flex-direction: column;
    }

    a {
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-items: center;
      gap: 0.5rem;

      &:hover {
        text-decoration: underline;
      }
      svg {
        color: var(--primary);

        font-size: 1.5rem;
      }
    }
  }

  &__item {
    display: flex;
    gap: 1.5rem;
    flex-direction: row;

    @include respond-below('phone') {
      flex-direction: column;
    }
  }

  &__email {
    flex-grow: 1;
  }

  &__email,
  &__phone {
    white-space: nowrap;
  }
}
</style>
