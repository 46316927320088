<template>
  <section class="poi-list">
    <template v-for="(item, index) in content">
      <div :key="index + 'table-desktop'" class="poi-list-desktop-table">
        <h2 class="demographics-section-title">{{ item.name }}</h2>
        <table class="poi-list-table">
          <thead>
            <tr>
              <th class="poi-list-stat poi-list-stat--first poi-list-stat--label">
                <!-- {{ item.name }} -->
              </th>
              <th v-if="item.showSchool" class="poi-list-stat">
                Klasser
              </th>
              <th v-if="item.showSchool" class="poi-list-stat">
                Elever
              </th>
              <th class="poi-list-stat">Avstand</th>
              <th class="poi-list-stat">Tid</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, idx) in item.pois" :key="idx">
              <td class="poi-list-stat poi-list-stat--first">
                <FontAwesomeIcon :icon="getIcon(value.poitypeId)" /> {{ value.name }}
              </td>
              <td v-if="item.showSchool" class="poi-list-stat">
                {{ $path('school.classes', value) }}
              </td>
              <td v-if="item.showSchool" class="poi-list-stat">
                {{ $path('school.students', value) }}
              </td>

              <td class="poi-list-stat">
                {{ $path(`distances.${value.distances.selected}`, value) }}
                {{ $path('distances.unit', value) }}
              </td>
              <td class="poi-list-stat">
                <FontAwesomeIcon :icon="getIcon(value.distances.selected)" />
                {{ toMinutes($path(`duration.${value.distances.selected}`, value)) }} min
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div :key="index + 'table-mobile'" class="poi-list-mobile-table">
        <h2 class="demographics-section-title">{{ item.name }}</h2>

        <div class="poi-list-mobile-table__wrapper" v-for="(value, idx) in item.pois" :key="idx">
          <span class="poi-list-mobile-table__label"
            ><FontAwesomeIcon :icon="getIcon(value.poitypeId)" /> {{ value.name }}</span
          >
          <div class="poi-list-mobile-table__items">
            <div v-if="item.showSchool">
              Klasser
            </div>
            <div v-if="item.showSchool">
              Elever
            </div>
            <div>Avstand</div>
            <div>Tid</div>
          </div>
          <div class="poi-list-mobile-table__values">
            <div v-if="item.showSchool">{{ $path('school.classes', value) }}</div>
            <div v-if="item.showSchool">{{ $path('school.students', value) }}</div>
            <div>
              {{ $path(`distances.${value.distances.selected}`, value) }}
              {{ $path('distances.unit', value) }}
            </div>

            <div>
              <FontAwesomeIcon :icon="getIcon(value.distances.selected)" />
              {{ toMinutes($path(`duration.${value.distances.selected}`, value)) }} min
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
export default {
  props: {
    value: Array,
  },

  computed: {
    content() {
      const nameMap = {
        'Varer/tjenester': 'Varer\xa0/\xa0Tjenester',
      }

      return this.value.map(v => {
        return {
          ...v,
          showSchool: Boolean(v.pois.find(poi => this.$path('school.classes', poi))),
          name: nameMap[v.name] || v.name,
        }
      })
    },
  },

  methods: {
    toMinutes(seconds) {
      if (!seconds) return ''
      return Math.floor(seconds / 60)
    },
    getIcon(id) {
      const map = {
        // 110 -> Avstand ?
        '210': ['fas', 'plane'],
        '220': ['fas', 'train'],
        '221': ['fas', 'train'],
        '230': ['fas', 'tram'],
        '235': ['fas', 'map-pin'],
        '240': ['fas', 'bus'],
        '250': ['fas', 'ship'],
        // 270 -> Elbil ?
        '310': ['fas', 'futbol'],
        '330': ['fas', 'dumbbell'],
        '401': ['fas', 'school'],
        '402': ['fas', 'school'],
        '500': ['fas', 'graduation-cap'],
        '550': ['fas', 'graduation-cap'],
        '551': ['fas', 'graduation-cap'],
        // 600 -> barnehage
        '700': ['fas', 'shopping-bag'],
        '900': ['fas', 'medkit'],
        '1000': ['fas', 'wine-bottle'],
        '1100': ['fas', 'shopping-cart'],
        walk: ['fas', 'walking'],
        drive: ['fas', 'car-side'],
        air: ['fas', 'plane'],
      }

      return map[id] || ['fas', 'question']
    },
  },
}
</script>

<style lang="scss">
.poi-list {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .poi-list-desktop-table {
    @include respond-below('phone') {
      display: none;
    }
  }

  &-table {
    // to make border-animation
    position: relative;
    top: -6.5rem;
    margin-bottom: -6.5rem;

    width: 100%;
    border-collapse: collapse;

    // tr th {
    //   border-bottom: 1px solid var(--primary);
    // }

    tbody {
      tr {
        &:first-of-type {
          td {
            padding-top: 3.5rem;
          }
        }
      }
    }

    .poi-list-stat {
      width: 6.25rem;
      text-align: right;
      padding-block: 0.3rem;

      vertical-align: bottom;

      &--first {
        width: unset;
        text-align: left;
      }

      &--label {
        font-weight: 400;
        font-size: 4.125rem;
        @include respond-below('phone') {
          font-size: 2rem;
        }
        // margin-bottom: 1rem;
        font-family: var(--third-font);
      }

      &--hidden {
        visibility: hidden;
      }
    }
  }

  &-mobile-table {
    display: none;
    @include respond-below('phone') {
      display: unset;
    }
    h3 {
      color: white;
      // border-bottom: 1px solid var(--primary);
    }
    &__wrapper {
      margin-bottom: 1.5em;
      padding-top: 0.4em;
      padding-bottom: 0.4em;
    }

    &__label {
      // display: flex;
    }
    &__items {
      display: flex;

      gap: 0.5em;
      margin-top: 1em;
      margin-bottom: 1em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      border-top: 1px solid rgb(72, 72, 74);
      border-bottom: 1px solid rgb(72, 72, 74);

      & > div {
        flex: 1;
      }
    }
    &__values {
      display: flex;

      gap: 0.5em;
      & > div {
        flex: 1;
      }
    }
  }
}
</style>
