import { GraphQLClient } from 'graphql-request'
import Queries from './graphql/queries'
import { mergeDeepRight } from 'ramda'

let urlNode = document.head.querySelector('[property="og:url"]')
let urlObj = urlNode ? new URL(urlNode.getAttribute('content').split('/residential')[0]) : null
let endpoint = ''

if (location.host === 'privatmegleren.no' && urlObj) {
  if (urlObj.hostname.split('.').length === 2) endpoint = `https://www.${urlObj.hostname}`
  else endpoint = `https://` + urlObj.hostname
}

function getResidentials(payload) {
  return new GraphQLClient(endpoint + '/api/graphql', {
    headers: {
      'accept-language': document.querySelector('html').getAttribute('lang'),
    },
  })
    .request(Queries.Residentials, mergeDeepRight({ filter: { status: ['!draft'] } }, payload))
    .then(data => data.ResidentialsPaginate)
}

export { getResidentials }
