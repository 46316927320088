<template>
  <ButtonComponent
    class="stats-button"
    theme="secondary"
    :label="label"
    @click="scrollToAnchor(hash)"
  />
</template>

<script>
export default {
  props: {
    label: String,
    hash: String,
  },
  methods: {
    scrollToAnchor(hash) {
      let target = document.querySelector(
        '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
      )
      if (!target) return
      target.scrollIntoView({ behavior: 'smooth' })
    },
  },
}
</script>

<style lang="scss">
.stats-button {
}
</style>
