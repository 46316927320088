import Bus from './bus'
import { eventBus as FlatfinderBus } from '@kvass/flatfinder/src/Component.vue'

import { getCookie } from '@/utils'

function loadScript() {
  return new Promise((resolve, reject) => {
    var api = document.head.querySelector('[property="og:url"]')
    api = api ? api.getAttribute('content').split('/residential')[0] : ''
    var script = document.createElement('script')
    script.src = api + '/api/project/script.js?id=' + getCookie('kvass.project')
    script.type = 'text/javascript'

    script.addEventListener('load', () => resolve())

    document.head.appendChild(script)
  })
}

FlatfinderBus.on('onChange', function() {
  Bus.emit('flatfinder:onChange', ...arguments)
})

export const scriptPromise = loadScript()

scriptPromise.then(() => window.Kvass.migrate(Bus))
export default Bus
